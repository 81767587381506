import { createAsyncThunk } from '@reduxjs/toolkit';
import {postBugReport } from 'services/API';

export const postBugReportThunk = createAsyncThunk(
    'antifraud/postrequest',
    async ( {shiftData, token}, thunkAPI) => {
          
          try {
            const response = await postBugReport(shiftData, token);
            return response;
          } catch (e) {
            return thunkAPI.rejectWithValue(e.message);
          }
        }
      );
    