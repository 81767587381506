import styled from 'styled-components';
import { PiTelegramLogo } from 'react-icons/pi';
import { IoShield } from 'react-icons/io5';

export const QaMessageAreaConteiner = styled.div`
  margin-left: 381px;
  position: fixed;
  width: 1182px;
  height: 100%;

  border-right: 1px solid #dfdaeb;
`;

export const MessageConteiner = styled.div`
  width: 1182px;
  height: 600px;
`;

export const TextAreaQa = styled.textarea`
  width: 742px;
  height: 45px;
  resize: none;
  position: absolute;
  top: 650px;
  left: 200px;
  background: #ffffff;
  box-shadow: 0px 2px 22.3px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  padding: 8px;
  padding-bottom: 65px;
`;

export const TextAreaToolsConteiner = styled.div`
  position: absolute;
  top: 730px;
  left: 230px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;
`;

export const TagBlock = styled.div``;

export const SendButton = styled.button`
  width: 49px;
  height: 20px;

  box-shadow: 0px 2px 22.3px rgba(0, 0, 0, 0.16);
  background: #0a1019;
  border-radius: 5px;

  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #ffffff;

  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const TagButton = styled.button`
  width: 90px;
  height: 20px;
  background: transparent;
  border: 0;

  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;

  color: #0a1019;

  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Telegram = styled(PiTelegramLogo)`
  color: #ffffff;
  width: 9px;
  height: 8px;
  margin-left: 5px;
`;

export const Shield = styled(IoShield)`
  color: #0a1019;
  width: 13px;
  height: 11px;
  margin-left: 5px;
`;
