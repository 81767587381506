import { createAsyncThunk } from '@reduxjs/toolkit';
import { getArchive } from 'services/API';


export const getArchiveThunk = createAsyncThunk(
  'archive/getAll',
  async ({token,id, page}, thunkAPI) => {
    try {
      const response = await getArchive(token, id, page);
      const data = response.d;
      return data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);