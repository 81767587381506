import { useState } from 'react';
import {
  BugReportModall,
  CloseBtn,
  FileInp,
  FileInpButton,
  FileInpButtonText,
  FileInpIcon,
  FileInpIconWrapper,
  GrayRadioButton,
  MainText,
  ModalContent,
  ModalForm,
  ModalFormButtonConteiner,
  ModalFormLabel,
  ModalFormTextArea,
  ModalSubmitButton,
  ModalText,
  RadioButtonContainer,
  RadioButtonLabel,
  WrapForInputFile,
} from './BugReportModal.styled';
import { Notify } from 'notiflix';
import { postBugReportThunk } from '../../../redux/bugReports/bugReportsActions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessToken } from '../../../redux/selectors';
import { downloadLogs } from '../../../services/logger';

const BugReportModal = ({ onClose }) => {
  const dispatch = useDispatch();
  const token = useSelector(selectAccessToken);
  const [selectedOption, setSelectedOption] = useState('');
  const [isChooseFilesSelected, setIsChooseFilesSelected] = useState(false);
  const [bugsLevel, setBugsLevel] = useState('');
  const [description, setDescription] = useState('');
  const [activity, setActivity] = useState('');
  const [files, setFiles] = useState('');

  const handleOutsideClick = e => {
    if (e.target.id === 'over') {
      onClose();
    }
  };

  const handleOptionChange = e => {
    setSelectedOption(e.target.value);
    setIsChooseFilesSelected(e.target.value === 'Choose Files');
  };

  const handleBugsLevelChange = e => {
    setBugsLevel(e.target.value);
  };

  const handleDescriptionChange = e => {
    setDescription(e.target.value);
  };

  const handleActivityChange = e => {
    setActivity(e.target.value);
  };
  const getFileNames = files => {
    if (!files) return '';
    return Array.from(files)
      .map(file => file.name)
      .join(', ');
  };
  const handleSendFeedback = async e => {
    const shiftData = new FormData();
    shiftData.append('description', description);
    shiftData.append('activity', activity);
    shiftData.append('status', bugsLevel);

    for (const file of files) {
      shiftData.append('images', file);
      
    }
    const response = await dispatch(postBugReportThunk({ token, shiftData }));
    
    if (response.meta.requestStatus === 'fulfilled') {
      Notify.success('Thank you for the report');
      onClose();
    } else {
      Notify.failure('Ooops! Something went wrong');
    }
  };
  return (
    <BugReportModall onClick={handleOutsideClick} id="over">
      <ModalContent>
        <CloseBtn onClick={onClose}>&times;</CloseBtn>
        <MainText>Feedback</MainText>
        <ModalForm>
          <ModalFormLabel>Description</ModalFormLabel>
          <ModalFormTextArea
            value={description}
            onChange={handleDescriptionChange}
          />
          <ModalFormLabel>Activity</ModalFormLabel>
          <ModalFormTextArea value={activity} onChange={handleActivityChange} />
          <ModalFormLabel>Importance</ModalFormLabel>
          <RadioButtonContainer>
            <RadioButtonLabel>
              <GrayRadioButton
                type="radio"
                value="high"
                checked={bugsLevel === 'high'}
                onChange={handleBugsLevelChange}
              />
              High
            </RadioButtonLabel>

            <RadioButtonLabel>
              <GrayRadioButton
                type="radio"
                value="normal"
                checked={bugsLevel === 'normal'}
                onChange={handleBugsLevelChange}
              />
              Normal
            </RadioButtonLabel>

            <RadioButtonLabel>
              <GrayRadioButton
                type="radio"
                value="low"
                checked={bugsLevel === 'low'}
                onChange={handleBugsLevelChange}
              />
              Low
            </RadioButtonLabel>

            <RadioButtonLabel>
              <GrayRadioButton
                type="radio"
                value="feature"
                checked={bugsLevel === 'feature'}
                onChange={handleBugsLevelChange}
              />
              Feature
            </RadioButtonLabel>
          </RadioButtonContainer>
          <ModalFormLabel for="fileReport">Files</ModalFormLabel>
          <ModalFormButtonConteiner>
            <RadioButtonLabel>
              <GrayRadioButton
                type="radio"
                value="Choose Files"
                checked={selectedOption === 'Choose Files'}
                onChange={handleOptionChange}
              />
              Choose Files
            </RadioButtonLabel>
            <RadioButtonLabel>
              <GrayRadioButton
                type="radio"
                value="No file chosen"
                checked={selectedOption === 'No file chosen'}
                onChange={handleOptionChange}
              />
              No file chosen
            </RadioButtonLabel>
          </ModalFormButtonConteiner>

          {isChooseFilesSelected && (
            <WrapForInputFile>
              <FileInp
                type="file"
                id="fileReport"
                name="fileReport"
                accept=".jpg, .jpeg, .png"
                multiple
                onChange={e => setFiles(e.target.files)}
              />
              <FileInpButton for="fileReport"  selected={files.length > 0}>
                <FileInpIconWrapper>
                  <FileInpIcon />
                </FileInpIconWrapper>
                <FileInpButtonText selected={files.length > 0}>
                  {files.length > 0
                    ? `Выбрано файлов: ${files.length} (${getFileNames(files)})`
                    : 'Выберите файл'}
                </FileInpButtonText>
              </FileInpButton>
              <ModalText>5 files maximum</ModalText>
            </WrapForInputFile>
          )}
        </ModalForm>
        <ModalSubmitButton onClick={handleSendFeedback}>
          Send Feedback
        </ModalSubmitButton>
        <ModalSubmitButton onClick={downloadLogs}>
          Download Logs
        </ModalSubmitButton>
      </ModalContent>
    </BugReportModall>
  );
};

export default BugReportModal;
