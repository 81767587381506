import { Container, Document } from './CreateDB.styled';

const CreateDB = () => {
  return (
    <Container>
      <Document />
    </Container>
  );
};

export default CreateDB;
