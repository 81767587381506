import { selectSwitcher } from '../../../redux/selectors';
import {
  Container,
  ItemListHeader,
  StyledNav,
  ListHeader,
  SearchContainer,
  SearchIcon,
  SearchInput,
  StyledNavCreate,
} from './HeaderDatBas.styled';
import { useSelector } from 'react-redux';

const HeaderDatBas = () => {
  const switcher = useSelector(selectSwitcher);

  return (
    <Container>
      <ListHeader>
        <ItemListHeader>
          <StyledNav to="/homeDataBase">Home</StyledNav>
        </ItemListHeader>
        <ItemListHeader>
          <StyledNav to="/spacesDataBase">Spaces</StyledNav>
        </ItemListHeader>
        <ItemListHeader>
          <StyledNav to="/recentDataBase">Recent</StyledNav>
        </ItemListHeader>
      </ListHeader>
      <SearchContainer>
        <SearchIcon />
        <SearchInput switcher={switcher} placeholder="Search"></SearchInput>
      </SearchContainer>
    </Container>
  );
};

export default HeaderDatBas;
