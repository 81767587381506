import HeaderDatBas from 'components/DataBas/HeaderDB/HeaderDatBas';
import ProjectPage from '../../../components/DataBas/ProjectPage/ProjectPage';
import { useParams } from 'react-router-dom';

const { DataBasePageConteiner } = require('../DataBasePage.styled');

const ProjectPagePage = () => {

  return (
    <DataBasePageConteiner>
      <HeaderDatBas />
      <ProjectPage />
    </DataBasePageConteiner>
  );
};
export default ProjectPagePage;
