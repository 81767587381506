import { useCallback, useEffect, useState } from 'react';
import {
  FrodWorkContainer,
  FrodWorkMainText,
  FrodWorkSubText,
  FrodWorkText,
  FrodWorkSubPar,
  FrodWorkComment,
  FrodTextArea,
  FrodButtonArea,
  RedButton,
  BlueButton,
  GreenButton,
  FrodSwitcher,
  FrodSwitherText,
  PurpleButton,
  TextAreaWrap,
  FileInp,
  FileInpIconWrapper,
  FileInpButtonText,
  FileInpButton,
  FileInpIcon,
  ImgWrap,
  CloseBtnIcon,
  FileCont,
  ImgImg,
  DocImg,
  FrodTLWorkSubText,
  BrownButton,
  HoldButton,
  DownArrow,
  HoldContainer,
  HoldTableContainer,
  GetNewButton,
  FrodWorkSubParSpan,
} from './FrodWorkPart.styled';

import Switch from 'react-switch';
import FrodTable from './FrodTable/FrodTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessToken,
  selectFraudHolds,
  selectFraudRequest,
  selectSwitcher,
  selectUserEscalade,
  selectUserType,
  selectUserisRequest,
} from '../../../redux/selectors';
import {
  getAntifraudHoldRequests,
  getAntifraudRequestThunk,
  postAntifraudRequestThunk,
} from '../../../redux/fraud/fraudActions';
import { Notify } from 'notiflix';
import { socket } from 'services/API';
import debounce from 'lodash.debounce';
import FrodTableHold from './FrodTableHold/FrodTableHold';
import EscaledeSound from '../../../sound/zvuk.mp3';
import useSound from 'use-sound';

const FrodWorkPart = ({ project }) => {
  const switcher = useSelector(selectSwitcher);
  const columns = [
    { Header: 'Project', accessor: 'project' },
    { Header: 'User ID', accessor: 'userId' },
    { Header: 'Request ID', accessor: 'requestId' },
    { Header: 'Sum', accessor: 'sum' },
    { Header: 'Type', accessor: 'type' },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Status', accessor: 'status' },
  ];

  const [isStatus, setIsStatus] = useState(null);
  const [isRequests, setIsRequests] = useState(
    JSON.parse(localStorage.getItem('savedRequests'))
  );
  const [isAutoNext, setIsAutoNext] = useState(
    JSON.parse(localStorage.getItem('savedIsAutoNext'))
  );
  const [isComment, setIsComment] = useState('');
  const [isButtons, setIsButtons] = useState(false);
  const [isResponse, setIsResponse] = useState(null);

  const [isMainData, setIsMainData] = useState(null);
  const [isPK, setIsPK] = useState(null);

  const [image, setImage] = useState(null);
  const [document, setDocument] = useState(null);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const dispatch = useDispatch();

  const token = useSelector(selectAccessToken);
  const request = useSelector(selectFraudRequest);
  const user = useSelector(selectUserType);
  const isUpdRequest = useSelector(selectUserisRequest);
  const holds = useSelector(selectFraudHolds);
  const escal = useSelector(selectUserEscalade);
  const [isHoldData, setIsHoldData] = useState();
  const [playEscal] = useSound(EscaledeSound);

  useEffect(() => {}, [isUpdRequest]);

  useEffect(() => {
    if (escal) {
      playEscal();
    }
  }, [escal]);

  useEffect(() => {
    const arr = [];

    holds?.map(h => {
      const formattedDate = formatDateTime(h.s_created_at);
      arr.push({
        pk: h.pk,
        project: h.s_project,
        userId: h.s_user_id,
        requestId: h.s_request_id,
        sum: h.s_amount,
        type: h.s_type,
        date: formattedDate,
        status: h.status,
        fundist_link: h.fundist_link,
      });
    });
    setIsHoldData(arr);
  }, [holds]);

  const [isViewComment, setIsViewComment] = useState(
    request?.request?.comments
  );

  const formatDateTime = dateTimeString => {
    const date = new Date(dateTimeString);
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;
  };

  const handleResponse = response => {
    const formattedDate = formatDateTime(
      response?.payload?.request?.s_created_at
    );
    setIsPK(response?.payload?.request?.pk);
    setIsStatus(response?.payload?.request?.status);
    setIsViewComment(response?.payload?.request?.comments);
    if (response.meta.requestStatus === 'fulfilled') {
      setIsComment('');
      setImage(null);
      setDocument(null);
      if (isAutoNext) {
        setIsResponse(response.payload.request);
        localStorage.setItem('savedEmpty', JSON.stringify(true));
      } else {
        setIsResponse(null);
        localStorage.setItem('savedEmpty', JSON.stringify(false));
      }
      Notify.success('Успешно!', { timeout: 1000 });
      setIsMainData([
        {
          project: response?.payload?.request?.s_project,
          userId: response?.payload?.request?.s_user_id,
          requestId: response?.payload?.request?.s_request_id,
          sum: response?.payload?.request?.s_amount,
          type: response?.payload?.request?.s_type,
          date: formattedDate,
          status: response?.payload?.request?.status,
          fundist_link: response?.payload?.request?.fundist_link,
        },
      ]);
    } else {
      localStorage.setItem('savedEmpty', JSON.stringify(false));
    }
  };

  const responseHandling = async shiftData => {
    const response = await dispatch(
      postAntifraudRequestThunk({ shiftData, token })
    );
    handleResponse(response);
  };

  const sendRequestDataForUnhold = async (isPK, decision) => {
    const shiftData = new FormData();
    shiftData.append('pk', isPK);
    shiftData.append('decision', decision);
    shiftData.append('comment', isComment);
    shiftData.append('type', isRequests ? 'escalades' : 'requests');
    shiftData.append('auto_get_next', isAutoNext);
    if (image?.file) {
      shiftData.append('files', image.file);
    }
    if (document?.[0]) {
      shiftData.append('files', document[0]);
    }
    await responseHandling(shiftData);
    setIsButtons(true);
    getNewBtn();
  };

  const sendRequestData = async decision => {
    const shiftData = new FormData();
    shiftData.append('pk', isPK);
    shiftData.append('decision', decision);
    shiftData.append('comment', isComment);
    shiftData.append('type', isRequests ? 'escalades' : 'requests');
    shiftData.append('auto_get_next', isAutoNext);
    if (image?.file) {
      shiftData.append('files', image.file);
    }
    if (document?.[0]) {
      shiftData.append('files', document[0]);
    }
    await responseHandling(shiftData);
  };

  const fetchData = async () => {
    try {
      const response = await dispatch(getAntifraudRequestThunk({ token }));

      const responseDate = response?.payload?.request?.s_created_at;
      const date = new Date(responseDate);

      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date
        .getHours()
        .toString()
        .padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

      if (response?.payload?.request != null) {
        setIsMainData([
          {
            project: response?.payload?.request?.s_project,
            userId: response?.payload?.request?.s_user_id,
            requestId: response?.payload?.request?.s_request_id,
            sum: response?.payload?.request?.s_amount,
            type: response?.payload?.request?.s_type,
            date: formattedDate,
            status: response?.payload?.request?.status,
            fundist_link: response?.payload?.request?.fundist_link,
          },
        ]);

        setIsStatus(response?.payload?.request?.status);
        setIsPK(response?.payload?.request?.pk);
        setIsResponse(response);
        setIsViewComment(response?.payload?.request?.comments);
      } else {
        if (response?.payload?.request === null) {
          Notify.failure('Закончились заявки.');
        }
        if (response?.meta?.requestStatus === 'rejected') {
          Notify.failure('Смена не начата.');
        }

        if (response?.payload?.can_go_out === false) {
          setIsRequests(!isRequests);
          localStorage.setItem('savedRequests', JSON.stringify(!isRequests));
        }
      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      const errorCode = error?.response?.status || 'неизвестная ошибка';
      Notify.failure(`Произошла ошибка: ${errorCode}. Сообщите в тех. поддержку`);
    }
  };

  const toggleIsAutoNext = () => {
    setIsAutoNext(!isAutoNext);
    localStorage.setItem('savedIsAutoNext', JSON.stringify(!isAutoNext));
  };

  useEffect(() => {
    if (isStatus === 'inprogress') {
      setIsButtons(false);
    } else {
      setIsButtons(true);
    }
  }, [isStatus]);

  const debouncedSubscribe = useCallback(
    debounce(() => {
      if (user === 'tl') {
        socket.send(
          JSON.stringify({
            action: 'subscribe',
            payload: {
              channel: 'antifraud_requests',
              project: 'all',
            },
          })
        );
      }
    }, 300),
    [socket]
  );

  useEffect(() => {
    debouncedSubscribe();
    const emp = JSON.parse(localStorage.getItem('savedEmpty'));
    if (isAutoNext || emp) {
      fetchData();
    }
  }, []);

  const ApproveBtn = async () => {
    if (isComment) {
      await sendRequestData('approve');
    } else {
      Notify.failure('Нужен комментарий.');
    }
  };

  const DeclineBtn = async () => {
    if (isComment) {
      await sendRequestData('decline');
    } else {
      Notify.failure('Нужен комментарий.');
    }
  };

  const EscaladeBtn = async () => {
    if (isComment) {
      await sendRequestData('escalade');
    } else {
      Notify.failure('Нужен комментарий.');
    }
  };

  const CloseBtn = async () => {
    if (isComment) {
      await sendRequestData('close');
    } else {
      Notify.failure('Нужен комментарий.');
    }
  };

  const HoldBtn = async () => {
    if (isComment) {
      await sendRequestData('hold');
    } else {
      Notify.failure('Нужен комментарий.');
    }
  };

  const SkipBtn = async () => {
    if (isComment) {
      await sendRequestData('skip');
    } else {
      Notify.failure('Нужен комментарий.');
    }
  };

  const getNewBtn = async () => {
    try {
      setIsAutoNext(true);
      localStorage.setItem('savedIsAutoNext', JSON.stringify(true));
      localStorage.setItem('savedEmpty', JSON.stringify(true));
  
      await fetchData();
  
      if (isResponse === null) {
        setIsButtonDisabled(true);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 5000);
        Notify.failure('Закончились заявки. Подождите.');
      } else if (!isRequests) {
        setIsButtons(false);
      } else {
        setIsButtons(true);
      }
    } catch (error) {
      console.error('Ошибка при получении данных:', error);
      const errorCode = error?.response?.status || 'неизвестная ошибка';
      Notify.failure(`Произошла ошибка: ${errorCode}. Сообщите в тех. поддержку`);
    }
  };

  const handlePaste = event => {
    const items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const file = items[i].getAsFile();
        const reader = new FileReader();

        reader.onload = () => {
          const dataUrl = reader.result;
          setImage({ file, dataUrl });
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const getFileNames = files => {
    if (!files) return '';
    return Array.from(files)
      .map(file => file.name)
      .join(', ');
  };

  const handleDeleteBtn = async () => {
    setImage(null);
  };

  const isImage = url => {
    return /\.(jpeg|jpg|png|gif)$/i.test(url);
  };

  const isFile = url => {
    return /\.(docx|pdf|txt|xlsx)$/i.test(url);
  };

  const [expanded, setExpanded] = useState(false);

  const handleButtonClick = () => {
    setExpanded(!expanded);
    if (expanded === false) {
      dispatch(getAntifraudHoldRequests(token));
    }
  };

  return (
    <>
      <FrodWorkContainer>
        <FrodWorkMainText>
          <FrodWorkText switcher={switcher}>
            {escal ? 'ESCALADE' : 'REQUESTS'}
          </FrodWorkText>
        </FrodWorkMainText>
        {user !== 'tl' ? (
          <FrodWorkSubText>
            <FrodWorkSubPar>
              Current project:{' '}
              <FrodWorkSubParSpan>{project}</FrodWorkSubParSpan>{' '}
            </FrodWorkSubPar>
            <FrodWorkSubPar>
              Open:{' '}
              <FrodWorkSubParSpan>
                {isUpdRequest === undefined ? '-' : isUpdRequest?.open}
              </FrodWorkSubParSpan>
            </FrodWorkSubPar>
            <FrodWorkSubPar>
              Escalade:{' '}
              <FrodWorkSubParSpan>
                {isUpdRequest === undefined ? '-' : isUpdRequest?.escalade}
              </FrodWorkSubParSpan>
            </FrodWorkSubPar>
            <FrodWorkSubPar>
              In progress:{' '}
              <FrodWorkSubParSpan>
                {isUpdRequest === undefined ? '-' : isUpdRequest?.inprogress}
              </FrodWorkSubParSpan>
            </FrodWorkSubPar>
          </FrodWorkSubText>
        ) : (
          <FrodTLWorkSubText>
            {isUpdRequest?.map(isUpdRequest => {
              return (
                <FrodWorkSubText>
                  <FrodWorkSubPar>
                    Current project:{' '}
                    <FrodWorkSubParSpan>
                      {isUpdRequest?.s_project__name}
                    </FrodWorkSubParSpan>
                  </FrodWorkSubPar>
                  <FrodWorkSubPar>
                    Open:{' '}
                    <FrodWorkSubParSpan>
                      {isUpdRequest?.open === undefined ||
                      isUpdRequest?.open === null
                        ? '-'
                        : isUpdRequest?.open}
                    </FrodWorkSubParSpan>
                  </FrodWorkSubPar>
                  <FrodWorkSubPar>
                    Escalade:{' '}
                    <FrodWorkSubParSpan>
                      {isUpdRequest?.escalade === undefined ||
                      isUpdRequest?.escalade === null
                        ? '-'
                        : isUpdRequest?.escalade}
                    </FrodWorkSubParSpan>
                  </FrodWorkSubPar>
                  <FrodWorkSubPar>
                    In progress:{' '}
                    <FrodWorkSubParSpan>
                      {isUpdRequest?.inprogress === undefined ||
                      isUpdRequest?.inprogress === null
                        ? '-'
                        : isUpdRequest?.inprogress}
                    </FrodWorkSubParSpan>
                  </FrodWorkSubPar>
                </FrodWorkSubText>
              );
            })}
          </FrodTLWorkSubText>
        )}
        {isResponse != null ? (
          <FrodTable columns={columns} data={isMainData || []} />
        ) : (
          <></>
        )}

        {isResponse != null ? (
          isViewComment?.map(comment => (
            <FrodWorkComment key={comment.text}>
              <span
                style={{
                  color:
                    comment.user_type === 'agent'
                      ? 'green'
                      : comment.user_type === 'tl'
                      ? 'purple'
                      : 'red',
                }}
              >
                {comment.user}
              </span>
              :{comment.text.toString()}
              <FileCont>
                {comment?.files?.map((file, index) => (
                  <>
                    {isImage(file.file) ? (
                      <a
                        key={index}
                        href={file.file}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <ImgImg />
                      </a>
                    ) : isFile(file.file) ? (
                      <a
                        key={index}
                        href={`http${
                          process.env.REACT_APP_SECURE === 'true' ? 's' : ''
                        }://${process.env.REACT_APP_BACKEND_URL}${file.file}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DocImg />
                      </a>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </FileCont>
            </FrodWorkComment>
          ))
        ) : (
          <></>
        )}
        {isResponse != null ? (
          <TextAreaWrap>
            <FrodTextArea
              value={isComment}
              onPaste={handlePaste}
              onChange={e => {
                setIsComment(e.target.value);
              }}
              placeholder="Коментарий к заявке..."
            />
            {image && (
              <ImgWrap>
                <img
                  src={image?.dataUrl}
                  alt="Pasted"
                  style={{
                    width: '100%',
                    height: 'auto',
                    maxWidth: '560px',
                    maxHeight: '300px',
                  }}
                />
                <CloseBtnIcon onClick={handleDeleteBtn}>&times;</CloseBtnIcon>
              </ImgWrap>
            )}
            <FileInp
              type="file"
              name="fileComment"
              accept=".docx, .xlsx"
              id="input__file"
              onChange={e => setDocument(e.target.files)}
            />
            <FileInpButton for="input__file" selected={document?.length > 0}>
              <FileInpIconWrapper>
                <FileInpIcon />
              </FileInpIconWrapper>
              <FileInpButtonText selected={document?.length > 0}>
                {document?.length > 0
                  ? `Выбрано файлов: ${document?.length} (${getFileNames(
                      document
                    )})`
                  : 'Выберите файл'}
              </FileInpButtonText>
            </FileInpButton>{' '}
          </TextAreaWrap>
        ) : (
          <></>
        )}

        <FrodButtonArea>
          {isResponse === null ? (
            <>
              <GetNewButton
                switcher={switcher}
                onClick={getNewBtn}
                disabled={isButtonDisabled}
              >
                Get new
              </GetNewButton>
            </>
          ) : isButtons ? (
            <>
              <BrownButton onClick={() => HoldBtn()}>Hold</BrownButton>
              <PurpleButton onClick={() => CloseBtn()}>Close</PurpleButton>
            </>
          ) : (
            <>
              <GreenButton onClick={() => ApproveBtn()}>Approve</GreenButton>
              <RedButton onClick={() => DeclineBtn()}>Decline</RedButton>
              <BlueButton onClick={() => EscaladeBtn()}>Escalade</BlueButton>
              <BrownButton onClick={() => HoldBtn()}>Hold</BrownButton>
              <RedButton onClick={() => SkipBtn()}>Skip</RedButton>
            </>
          )}
        </FrodButtonArea>

        <FrodSwitcher>
          <FrodSwitherText>Automatically get next</FrodSwitherText>
          <Switch
            checked={isAutoNext}
            onChange={toggleIsAutoNext}
            onColor="#1EAF69"
            onHandleColor="#1EAF69"
            handleDiameter={30}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
            height={20}
            width={48}
            className="react-switch"
            id="material-switch"
          />
        </FrodSwitcher>
        {isResponse === null ? (
          <HoldContainer>
            <HoldButton expanded={expanded} onClick={handleButtonClick}>
              Hold list
              <DownArrow />
            </HoldButton>
            <HoldTableContainer expanded={expanded}>
              <FrodTableHold
                columns={columns}
                data={isHoldData || []}
                handleButtonClick={handleButtonClick}
                sendRequestDataForUnhold={sendRequestDataForUnhold}
                setExpanded={setExpanded}
              />
            </HoldTableContainer>
          </HoldContainer>
        ) : (
          <></>
        )}
      </FrodWorkContainer>
    </>
  );
};

export default FrodWorkPart;
