import styled from 'styled-components';

import { LiaPenNibSolid } from 'react-icons/lia';
import { RxCross2 } from 'react-icons/rx';

export const Container = styled.div`
  margin: 30px;
  position: relative;
`;

export const TextNameFile = styled.h2`
  font-family: 'Inter';
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #414141;
  margin: 0;
  color: ${props => (props.switcher ? '#D8D8D8' : '#242424')};
`;

export const ContainerMainInfo = styled.div`
  margin-left: 160px;
  width: 850px;
`;

export const ContainerProfile = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const UserImg = styled.img`
  width: 37px;
  height: 37px;
  margin-right: 20px;
  border-radius: 50%;
`;

export const UserText = styled.div`
  margin-right: 10px;
`;

export const UserTitle = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #7b7b7b;
  margin: 0;
  /* color: ${props => (props.switcher ? '#fff' : '#000000')}; */
`;

export const MainInfo = styled.p`
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: pre-wrap;
  color: ${props => (props.switcher ? '#D8D8D8' : '#242424')};
`;

export const DatBasePic = styled.img`
  width: 230px;
  height: 200px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
`;

export const ImgContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const HeaderFileWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Pen = styled(LiaPenNibSolid)`
  color: #414141;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #414141;
  cursor: pointer;
  &:hover {
    color: green;
    border: 2px solid green;
  }
`;

export const EditTextArea = styled.textarea`
  min-height: 350px;
  width: 725px;
  background: #ffffff;
  border: 0.5px solid #d3d3d3;
  border-radius: 3px;
  resize: none;
  outline: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #717171;
`;

export const EditTitleArea = styled.textarea`
  height: 25px;
  width: 450px;
  background: #ffffff;
  border: 0.5px solid #d3d3d3;
  border-radius: 3px;
  resize: none;
  outline: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #717171;
`;

export const FileInputContainer = styled.div`
  width: 230px;
  height: 200px;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  background-color: #333;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

export const PhotoLabel = styled.label`
  width: 230px;
  display: inline-block;
  margin-right: 20px;
`;

export const FileInputIcon = styled.span`
  z-index: 3;
  font-size: 24px;
  position: absolute;
  color: white;
  font-size: 48px;
`;

export const LoadArticle = styled.div`
  margin: 30px;
`;

export const SettingWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const SaveButton = styled.button`
  margin-right: 15px;
  cursor: pointer;
  background-color: #1eaf69;
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
  border: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: #1f803c;
  }
`;

export const CancelButton = styled.button`
  margin-right: 15px;
  cursor: pointer;
  background-color: #f01e2c;
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
  border: none;
  transition: background-color 0.3s;
  &:hover {
    background-color: #c30010;
  }
`;

export const RedCross = styled(RxCross2)`
  width: 18px;
  height: 18px;
  color: black;
`;
export const CloseButton = styled.div`
  position: absolute;
  background-color: white;
  top: -10px;
  right: -10px;
  width: 18px;
  height: 18px;
  border: 1px solid black;
  border-radius: 50px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 5;
  &:hover {
    border: 1px solid red;
    ${RedCross} {
      color: red;
    }
  }
`;

export const SettingPhotoWrap = styled.div`
display:flex;

border-top: 2px solid #878787;
`;