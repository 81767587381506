import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const QaSideBarConteiner = styled.div`
  display: inline-block;
  padding-top: 20px;
  width: 100px;
  position: fixed;

  border-right: 1px solid #dfdaeb;
  height: 100%;
`;

export const QaSideBarList = styled.ul`
  list-style: none;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;

  color: #b7b0c7;
  flex-direction: column;
  padding-left: 0;

  display: flex;
  align-items: center;
`;

export const QaSideBarItem = styled.li`
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const QaSideBarImg = styled.img`
  width: 14px;
  height: 14px;
  padding-left: 10px;
`;

export const MainTextQaSideBar = styled.p``;

export const QaSideBarLink = styled(NavLink)`
  text-decoration: none;
  color: #B7B0C7;
`;



