import styled from 'styled-components';

export const FrodMainText = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: ${props => (props.switcher ? '#fff' : '#000')};
  margin-bottom: 10px;
`;

export const FrodMainSpan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #1eaf69;
`;

export const FrodSubText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #b1b1b1;
  margin-bottom: 30px;
`;

export const FrodSubSpan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #1eaf69;
`;

export const FrodSelectForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const FrodSelectFormLabel = styled.label`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #b1b1b1;
  margin-bottom: 3px;
`;

export const FrodSelectFormButton = styled.button`
  width: 130px;
  height: 30px;
  border: 1px solid #d3d3d3;
  background-color: transparent;
  filter: drop-shadow(0px 4px 3.7px rgba(0, 0, 0, 0.06));
  border-radius: 3px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #1eaf69;
  transition: border-color 0.3s ease;
  &:hover {
    border-color: #4CAF50; /* Цвет рамки при наведении */
  }

  cursor: pointer;
`;

export const FrodSelect = styled.select`
  width: 425px;
  height: 26px;
  border: 1px solid #D3D3D3;
  border-radius: 2px;
  background-color: #fff;
  font-size: 16px;
  color: #000;
  margin-bottom: 40px;
  transition: border-color 0.3s ease; /* добавим плавное переходное состояние */

  &:focus {
    outline: none;
  }

  &:hover {
    border-color: #4CAF50; /* Цвет рамки при наведении */
  }

  &:first-of-type {
    margin-bottom: 10px; /* Сброс отступа для всех элементов, кроме первого */
  }
`;

export const FrodOption = styled.option`
  padding: 5px;
  color: #333;

  &:hover {
    background-color: #f5f5f5;
  }

  &.selected {
    background-color: #ccc;
    color: #fff;
  }
`;
