import {
  CardNumGreenText,
  CardNumText,
  CradContainer,
  InfoCard,
  InfoDiv,
  InfoHeader,
  SmallText,
} from './InfoBar.styled';
import { useSelector } from 'react-redux';
import { selectSwitcher,  } from '../../../../redux/selectors';
const InfoBar = ({ week, day, month, header }) => {
  const switcher = useSelector(selectSwitcher);
  return (
    <InfoDiv>
      <InfoHeader>{header}</InfoHeader>
      <CradContainer>
        <InfoCard>
          <CardNumText switcher={switcher}>{week}</CardNumText>
          <SmallText>неделя</SmallText>
        </InfoCard>
        <InfoCard>
          <CardNumGreenText>{day}</CardNumGreenText>
          <SmallText>сегодня</SmallText>
        </InfoCard>
        <InfoCard>
          <CardNumText switcher={switcher}>{month}</CardNumText>
          <SmallText>месяц</SmallText>
        </InfoCard>
      </CradContainer>
    </InfoDiv>
  );
};

export default InfoBar;
