import {
  QaArchiveMainCon,
  QaFilterCon,
  FilterButton,
  QaArchiveProfileCon,
  QaArchProfileImg,
  QaStyledInput,
  QaArchProfileInfo,
  QaArchProfileName,
  QaArchProfileEmail,
  QaTicketWrap,
  QaTicketInd,
  QaTicketName,
  QaAgentName,
  QaSolvedInfo,
  QaTimeInfo,
  QaDopSettings,
  SettingDotsIcon,
  NavItem,
} from './QaArchiveMain.styled';

const QaArchiveMain = () => {
  return (
    <QaArchiveMainCon>
      <QaFilterCon>
        <FilterButton>+ Add Filter</FilterButton>
      </QaFilterCon>
      <QaArchiveProfileCon>
        <QaStyledInput type="checkbox" />
        <NavItem to="/GHTIX">
          <QaArchProfileImg />
          <QaArchProfileInfo>
            <QaArchProfileName>Артем Ковальцев</QaArchProfileName>
            <QaArchProfileEmail>artemkov982@gmail.com</QaArchProfileEmail>
          </QaArchProfileInfo>
          <QaTicketWrap>
            <QaTicketInd></QaTicketInd>
            <QaTicketName>Ticket from chat GHTIX</QaTicketName>
          </QaTicketWrap>
          <QaAgentName>Agent name 2131</QaAgentName>
          <QaSolvedInfo>SOLVED</QaSolvedInfo>
          <QaTimeInfo>30 seconds ago</QaTimeInfo>
        </NavItem>
        <QaDopSettings>
          <SettingDotsIcon />
        </QaDopSettings>
      </QaArchiveProfileCon>
    </QaArchiveMainCon>
  );
};

export default QaArchiveMain;
