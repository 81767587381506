import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessToken,
  selectUserDepartment,
  selectUserType,
  selectNodeDataBase,
} from '../../../redux/selectors';
import { getDataBaseNodeThunk } from '../../../redux/database/databaseActions';

import { StyledNav, ProjList, ProjName, ProjItem } from './HomeDB.styled';
import { useEffect, useState } from 'react';
import { Load } from 'components/App.styled';
import { MutatingDots } from 'react-loader-spinner';

const HomeDB = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectAccessToken);
  const depart = useSelector(selectUserDepartment);
  const utype = useSelector(selectUserType);

  const uNode = useSelector(selectNodeDataBase);
  const [firstNode, setFirstNode] = useState();

  const loading = useSelector(state => state.database.loading);

  useEffect(() => {
    dispatch(getDataBaseNodeThunk({ token, id: 'null' }));
  }, []);

  useEffect(() => {
    setFirstNode(uNode);
    if (utype !== 'admin') {
      if (uNode) {
        const result = uNode?.nodes?.find(item => item.subtitle === depart);
        if (result) {
          window.location.href = `/projectPage/${result.id}`;
        }
      }
    }
  }, [uNode]);

  return (
    <>
      {loading ? (
        <Load>
          <MutatingDots
            height="100"
            width="100"
            color="#AFFFB7"
            secondaryColor="#AFFFB7"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </Load>
      ) : (
        <ProjList>
          {firstNode?.nodes?.map(node => {
            return (
              <StyledNav to={`/projectPage/${node.id}`}>
                <ProjItem>
                  <ProjName>{node.title}</ProjName>
                </ProjItem>
              </StyledNav>
            );
          })}
        </ProjList>
      )}
    </>
  );
};

export default HomeDB;
