import HeaderDatBas from "components/DataBas/HeaderDB/HeaderDatBas";

const { DataBasePageConteiner } = require("../DataBasePage.styled")




const RecentDBPage = () => {
    return (
       <DataBasePageConteiner>
        <HeaderDatBas/>
       </DataBasePageConteiner>
    );
    };
    export default RecentDBPage;