import styled from 'styled-components';

export const QaTicketDaskConteiner = styled.div`
  margin-left: 1562px;
  width: 480px;
  height: 100%;
  position: fixed;
`;

export const MainTextQaTicketDask = styled.h1`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;

  margin-top: 19px;
  margin-left: 30px;

  color: #000000;
`;

export const QaTicketDaskForm = styled.form`
  margin-top: 19px;
  margin-left: 30px;
  display: flex;
  flex-direction: column;
`;

export const QaTicketDaskLabel = styled.label`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 15px;

  display: flex;
  flex-direction: column;

  margin-bottom: 10px;

  color: #0a1019;
`;

export const QaTicketDaskInput = styled.input`
  border: 1px solid #dfdaeb;
  border-radius: 5px;

  margin-top: 10px;

  width: 270px;
  height: 25px;
`;

export const QaTicketDaskSelect = styled.select`
  border: 1px solid #dfdaeb;
  border-radius: 5px;

  margin-top: 10px;

  width: 270px;
  height: 25px;
`;

export const QaTicketDaskOption = styled.option`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const AddTagButton = styled.button`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: #1eaf69;

  width: 100px;
  height: 16px;

  margin-top: 15px;
  margin-bottom: 15px;
  background-color: transparent;

  border: 0;
  cursor: pointer;
`;

export const QaTicketDaskTextArea = styled.textarea`
  border: 1px solid #dfdaeb;
  border-radius: 5px;
  margin-top: 10px;
  resize: none;

  width: 270px;
  height: 100px;
`;

export const CheckInput = styled.input`
  height: 17px;
  width: 17px;

  background: #1eaf69;

  margin-right: 2px;
`;

export const CheckBoxConteiner = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckBoxText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 250;
  font-size: 12px;
  line-height: 15px;

  color: #0a1019;
`;

export const QaTicketDaskSendButton = styled.button`
  background: #1eaf69;
  border-radius: 5px;
  width: 270px;
  height: 25px;

  border: 0;

  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  color: #ffffff;

  cursor: pointer;
`;

export const QaTicketDaskTextForm = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #b7b0c7;

  width: 201px;
  height: 26px;
  text-align: center;

  margin-left: 40px;
`;

export const RequesterTicketsBlock = styled.div`
  margin-top: 19px;
  margin-left: 30px;
    `;

export const SecondMainText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const RequesterTicketsList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const RequesterTicketsItem = styled.li`
  display: flex;
  align-items: center;
`;

export const ClosedBlock = styled.div`
  width: 43px;
  height: 10px;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 8px;
  text-align: center;

  color: #ffffff;

  background: #8a8a8a;
  border-radius: 2px;
  transform: matrix(1, 0, 0, -1, 0, 0);

  margin-right: 5px;
`;

export const LinkRequesterTickets = styled.a`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;

  color: #ae8cff;
`;

export const RequesterTicketsText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #b7b0c7;
`;
