import React from 'react';
import { useTable } from 'react-table';
import {
  FrodTableContainer,
  FrodTableHeader,
  FrodTableCell,
  Link,
} from './FrodTableHold.styled';
import { selectSwitcher } from '../../../../redux/selectors';
import { useSelector } from 'react-redux';

const FrodTableHold = ({
  columns,
  data,
  handleButtonClick,
  sendRequestDataForUnhold,
  setExpanded
}) => {
  const switcher = useSelector(selectSwitcher);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });
  return (
    <FrodTableContainer {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <FrodTableHeader
                {...column.getHeaderProps()}
                onClick={() => handleButtonClick()}
              >
                {column.render('Header')}
              </FrodTableHeader>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, index) => (
                <FrodTableCell
                  switcher={switcher}
                  {...cell.getCellProps()}
                  onClick={() => {
                    sendRequestDataForUnhold(cell?.row?.original?.pk, 'unhold');
                    setExpanded(false);
                  }}
                >
                  {index === 1 ? (
                    <Link
                      href={cell?.row?.original?.fundist_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {cell.render('Cell')}
                    </Link>
                  ) : (
                    cell.render('Cell')
                  )}
                </FrodTableCell>
              ))}
            </tr>
          );
        })}
      </tbody>
    </FrodTableContainer>
  );
};

export default FrodTableHold;
