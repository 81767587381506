import styled from 'styled-components';

export const FrodWorkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 200px;
`;

export const FrodWorkMainText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FrodWorkText = styled.h2`
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #b1b1b1;
  text-align: center;
`;

export const FrodTimer = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 800;
  font-size: 186px;
  line-height: 225px;
  margin: 50px 0 0 0 ;
  &.greyBtn{
    color: #b1b1b1;
  }
  &.startBtn {
    color: #1EAF69;
  }
  &.stopBtn {
    color: #F4BE33;
  }
`;
export const FrodBtn = styled.button`
cursor:pointer;
width: 100px;
height: 27px;
flex-shrink: 0;
border-radius: 3px;
border: 1px solid #D3D3D3;
box-shadow: 0px 4px 3.7px 0px rgba(0, 0, 0, 0.06);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
&.startBtn {
  color: #1EAF69;
}
&.stopBtn {
  color: #FD3535;
}
`;

export const BreakBtn = styled.button`
margin-left:20px;
width: 100px;
height: 27px;
flex-shrink: 0;
border-radius: 3px;
border: 1px solid #D3D3D3;
box-shadow: 0px 4px 3.7px 0px rgba(0, 0, 0, 0.06);
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
color: #F4BE33;
cursor:pointer;
`;

export const BackToWorkBtn = styled.button`
border-radius: 3px;
border: 1px solid #D3D3D3;
box-shadow: 0px 4px 3.7px 0px rgba(0, 0, 0, 0.06);
color: #1EAF69;
text-align: center;
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 600;
cursor:pointer;
line-height: normal;`;

export const BtnWrap = styled.div`
display:flex;
`;