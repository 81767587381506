import CreateDB from "components/DataBas/CreateDB/CreateDB";
import HeaderDatBas from "components/DataBas/HeaderDB/HeaderDatBas";

const { DataBasePageConteiner } = require("../DataBasePage.styled")




const CreateNewDBPage = () => {
    return (
       <DataBasePageConteiner>
        <HeaderDatBas/>
        <CreateDB/>
       </DataBasePageConteiner>
    );
    };
    export default CreateNewDBPage;