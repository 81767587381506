import { createSlice } from '@reduxjs/toolkit';
import {
  getUsersThunk,
  getScheduleCreationThunk,
  postScheduleCreationThunk,
  putScheduleShiftThunk,
  getSchedulePersonalTableThunk,
  getScheduleLunchWorkerThunk,
  getScheduleTbreakWorkerThunk,
  updateTableThunk,
} from './scheduleOperations';

const initialState = {
  isLoading: false,
  schedule: null,
  scheduleCreation: null,
  scheduleCreationResult: null,
  shift: null,
  workerLunch: null,
  workerTbreak: null,
  error: null,
  personal_shifts: null,
  personal_tags: null,
  table: null,
};

const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setTable: (state, action) => {
      state.table = action.payload;
    },
    clearTable: (state, action) => {
      state.table = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getUsersThunk.pending, state => {
        state.isLoading = true;
      })
      .addCase(getUsersThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.schedule = action.payload;
      })
      .addCase(getUsersThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getScheduleCreationThunk.pending, state => {
        state.isLoading = true;
      })
      .addCase(getScheduleCreationThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.scheduleCreation = action.payload;
      })
      .addCase(getScheduleCreationThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getScheduleLunchWorkerThunk.pending, state => {
        state.isLoading = true;
      })
      .addCase(getScheduleLunchWorkerThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.workerLunch = action.payload.available;
      })
      .addCase(getScheduleLunchWorkerThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getScheduleTbreakWorkerThunk.pending, state => {
        state.isLoading = true;
      })
      .addCase(getScheduleTbreakWorkerThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.workerTbreak = action.payload.available;
      })
      .addCase(getScheduleTbreakWorkerThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(postScheduleCreationThunk.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(postScheduleCreationThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.scheduleCreationResult = action.payload;
      })
      .addCase(postScheduleCreationThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(putScheduleShiftThunk.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(putScheduleShiftThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.shift = action.payload;
      })
      .addCase(putScheduleShiftThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(getSchedulePersonalTableThunk.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getSchedulePersonalTableThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.personal_shifts = action.payload.shifts;
        state.personal_tags = action.payload.tags;
      })
      .addCase(getSchedulePersonalTableThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setTable, clearTable } = scheduleSlice.actions;
export default scheduleSlice.reducer;
