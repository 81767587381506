import styled from 'styled-components';
import { BiDotsHorizontal } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';

export const QaArchiveMainCon = styled.div`
  margin-left: 394px;
  position: fixed;
  width: 2000px;
`;

export const QaFilterCon = styled.div`
  height: 64px;
  border-bottom: 1px solid #dfdaeb;
`;

export const FilterButton = styled.button`
  margin-top: 15px;
  margin-left: 15px;
  width: 126px;
  height: 30px;
  border-radius: 5px;
  opacity: 0px;
  border: 1px solid #1eaf69;
  font-family: Geologica;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: #1eaf69;
  background-color: transparent;

  cursor: pointer;
`;

export const QaArchiveProfileCon = styled.div`
  height: 50px;
  border-bottom: 1px solid #dfdaeb;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const QaStyledInput = styled.input`
  margin-left: 15px;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border: 1px;
  opacity: 0px;
`;

export const QaArchProfileImg = styled.img`
  height: 30px;
  width: 30px;
  border-radius: 10px;
  margin-left: 15px;
  margin-right: 9px;
`;
export const QaArchProfileInfo = styled.div`
  display: flex;
  margin-right: 100px;
  flex-direction: column;
`;

export const QaArchProfileName = styled.h3`
  font-family: Geologica;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  text-align: left;
  margin: 0;

  color: #0a1019;
`;

export const QaArchProfileEmail = styled.h3`
  font-family: Geologica;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  text-align: left;
  margin: 0;

  color: #8a8a8a;
`;

export const QaTicketWrap = styled.div`
  display: flex;
  align-items: center;
  margin-right: 150px;
`;

export const QaTicketInd = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 2px;
  background: #8a8a8a;
`;

export const QaTicketName = styled.h3`
  font-family: Geologica;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
  color: #0a1019;
  margin-left: 10px;
`;

export const QaAgentName = styled.h3`
  font-family: Geologica;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  text-align: left;
  color: #0a1019;
  margin-right: 60px;
`;

export const QaSolvedInfo = styled.div`
  width: 50px;
  height: 16px;
  border-radius: 4px;
  background-color: #1eaf69;
  color: #fff;
  font-family: Geologica;
  font-size: 8px;
  font-weight: 400;
  line-height: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 60px;
`;

export const QaTimeInfo = styled.h3`
  font-family: Geologica;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  text-align: left;
  color: #8a8a8a;
  margin-right: 60px;
`;

export const QaDopSettings = styled.button`
  background-color: transparent;
  border: none;
`;

export const SettingDotsIcon = styled(BiDotsHorizontal)`
  color: #8a8a8a;
  width: 30px;
  height: 35px;
`;
export const NavItem = styled(NavLink)`
  display: contents;
  text-decoration: none;
`;
