import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAntifraudRequest,
  getAntifraudRequestEscalade,
  getAntifraudShift,
  getFraudPersonalStatistics,
  getFraudTlStatistics,
  getHoldRequests,
  postAntifraudRequest,
  postAntifraudShift,
  getFraudTeamStatistics,
  getFraudPersonalStatisticsPk,
} from 'services/API';

export const getAntifraudShiftThunk = createAsyncThunk(
  'antifraud/getshift',
  async (token, thunkAPI) => {
    try {
      const response = await getAntifraudShift(token);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const postAntifraudShiftThunk = createAsyncThunk(
  'antifraud/postshift',
  async ({ shiftData, token }, thunkAPI) => {
    try {
      const response = await postAntifraudShift(shiftData, token);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getAntifraudRequestThunk = createAsyncThunk(
  'antifraud/getrequest',
  async ({ token }, thunkAPI) => {
    try {
      const response = await getAntifraudRequest(token);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const postAntifraudRequestThunk = createAsyncThunk(
  'antifraud/postrequest',
  async ({ shiftData, token }, thunkAPI) => {
    try {
      const response = await postAntifraudRequest(shiftData, token);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getAntifraudRequestEscaladeThunk = createAsyncThunk(
  'antifraud/request?type=escalades',
  async (token, thunkAPI) => {
    try {
      const response = await getAntifraudRequestEscalade(token);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getAntifraudHoldRequests = createAsyncThunk(
  'antifraud/getholdrequests',
  async (token, thunkAPI) => {
    try {
      const response = await getHoldRequests(token);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getFraudPersonalStatisticsThunk = createAsyncThunk(
  'antifraud/getPersonalStatistics',
  async ({ token, startDate, dayType }, thunkAPI) => {
    try {
      const response = await getFraudPersonalStatistics(
        token,
        startDate,
        dayType
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getFraudPersonalStatisticsPkThunk = createAsyncThunk(
  'antifraud/getPersonalStatisticsPk',
  async ({ token, agent, startDate, dayType }, thunkAPI) => {
    try {
      const response = await getFraudPersonalStatisticsPk(
        token,
        agent,
        startDate,
        dayType
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getFraudTlStatisticsThunk = createAsyncThunk(
  'antifraud/getTlStatistics',
  async ({ token, startDate, dayType }, thunkAPI) => {
    try {
      const response = await getFraudTlStatistics(token, startDate, dayType);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getFraudTeamStatisticsThunk = createAsyncThunk(
  'antifraud/getTeamStatistics',
  async ({ token, project, startDate, dayType }, thunkAPI) => {
    try {
      const response = await getFraudTeamStatistics(
        token,
        project,
        startDate,
        dayType
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
