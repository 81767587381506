import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  InputContainer,
  TimeWindowModal,
  TimeSelect,
  LunchTimeSelect,
  TimeOptions,
  TimeText,
  Confirm,
  ConfirmButton,
  SpanWindow,
} from './TimeWindow.styled';
import { useDispatch } from 'react-redux';
import {
  selectScheduleWorkerLunch,
  selectScheduleWorkerTbreak,
  selectAccessToken,
  selectUserIsUser,
  getUser,
  selectUserShift,
} from '../../redux/selectors';
import { postScheduleWorkerThunk } from '../../redux/schedule/scheduleOperations';
import { Notify } from 'notiflix';

const TimeWindow = ({ open }) => {
  const lunch = useSelector(selectScheduleWorkerLunch);
  const tbreak = useSelector(selectScheduleWorkerTbreak);
  const token = useSelector(selectAccessToken);
  const shift = useSelector(selectUserShift);
  const [isData, setData] = useState();
  const dispatch = useDispatch();
  const [selectedLunchTime, setSelectedLunchTime] = useState(null);
  const [selectedTBreakTime, setSelectedTBreakTime] = useState(null);

  useEffect(()=>{
    setSelectedTBreakTime(tbreak ? tbreak[0] : null)
    setSelectedLunchTime(lunch ? lunch[0] : null)
  },[lunch, tbreak])

  const timeArray = arr => {
    return arr?.map(seconds => {
      const date = new Date();
      date.setUTCHours(0, 0, seconds, 0);
      const formattedTime = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      });
      return { times: formattedTime, secs: seconds };
    });
  };

  useEffect(() => {
    setData(shift);
  }, [shift]);

  const ConfirmLunch = async () => {
    const res = await dispatch(
      postScheduleWorkerThunk({
        token,
        scheduleData: { action: 'take_lunch', value: selectedLunchTime },
      })
    );
  

    if (res.meta.requestStatus !== 'fulfilled') {
      Notify.failure('Something wrong');
    }
  };
  const ConfirmTBreak = async () => {
    const res = await dispatch(
      postScheduleWorkerThunk({
        token,
        scheduleData: { action: 'take_tbreak', value: selectedTBreakTime },
      })
    );
    if (res.meta.requestStatus !== 'fulfilled') {
      Notify.failure('Something wrong');
    }
  };

  const FromSecsToTime = seconds => {
    const date = new Date();
    date.setUTCHours(0, 0, seconds, 0);
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
    return formattedTime;
  };

  return (
    <>
      {open && (
        <TimeWindowModal className="modal">
          <InputContainer>
            <TimeText>Обед:</TimeText>
            {isData?.lunch?.start_at !== undefined &&
            isData?.lunch?.start_at !== null ? (
              <span>
                с {FromSecsToTime(isData?.lunch?.start_at)} до{' '}
                {FromSecsToTime(isData?.lunch?.end_at)}
              </span>
            ) : lunch?.length !== 0 ? (
              <>
                <LunchTimeSelect
                  onChange={e => {setSelectedLunchTime(e.target.value);}}
                >
                  {timeArray(lunch)?.map(time => (
                    <TimeOptions key={time.times} value={time.secs}>
                      {time.times}
                    </TimeOptions>
                  ))}
                </LunchTimeSelect>
                <ConfirmButton onClick={() => ConfirmLunch()}>
                  <Confirm />
                </ConfirmButton>
              </>
            ) : (
              <SpanWindow>Обед отсутствует</SpanWindow>
            )}
          </InputContainer>
          <InputContainer>
            <TimeText>Перерыв:</TimeText>
            {isData?.tbreak?.start_at !== undefined &&
            isData?.tbreak?.start_at !== null &&
            isData?.tbreak?.is_expired === false ? (
              <span>
                c {FromSecsToTime(isData?.tbreak?.start_at)} до{' '}
                {FromSecsToTime(isData?.tbreak?.end_at)}
              </span>
            ) : tbreak?.length !== 0 ? (
              <>
                <TimeSelect
                  onChange={e => {setSelectedTBreakTime(e.target.value);}}
                >
                  {timeArray(tbreak)?.map(time => (
                    <TimeOptions key={time.times} value={time.secs}>
                      {time.times}
                    </TimeOptions>
                  ))}
                </TimeSelect>
                <ConfirmButton onClick={() => ConfirmTBreak()}>
                  <Confirm />
                </ConfirmButton>
              </>
            ) : (
              <SpanWindow>Перерыв отсутствует</SpanWindow>
            )}
          </InputContainer>
        </TimeWindowModal>
      )}
    </>
  );
};

export default TimeWindow;
