import sessionStorage from 'redux-persist/lib/storage/session';
import persistReducer from "redux-persist/es/persistReducer";
import authReducer from "./auth/authSlice";
import userReducer from "./user/userSlice";
import profileSlice from "./profile/profileSlice";
import fraudReducer from './fraud/fraudSlice';
import cashbackReducer from './cashback/cashbackSlice';
// import storage from 'redux-persist/lib/storage';
import {combineReducers} from 'redux';
import scheduleReducer from "./schedule/scheduleSlice"
import timerReducer from "./timer/timerSlice"
import { switcherReducer } from "./switcher/switcherReducer";
import databaseSlice from "./database/databaseSlice";
import archiveSlice from "./archive/archiveSlice";

const persistConfig = {
  key: 'token',
  version: 1,
  storage: sessionStorage,
  whitelist: ["auth", "user","profile","fraud"],
};

 const rootReducer = combineReducers({
    switcher: switcherReducer,
    auth: authReducer,
    user : userReducer,
    profile : profileSlice,
    schedule: scheduleReducer,
    fraud : fraudReducer,
    cashback : cashbackReducer,
    timer: timerReducer,
    database:databaseSlice,
    archive : archiveSlice
  });

const persistedAuthReducer = persistReducer(persistConfig, rootReducer)

  
  export default persistedAuthReducer;