import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  startTime: null,
  pausedTime: null,
  elapsedTime: 0,
  isActive: false,
};
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('timerState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('timerState', serializedState);
  } catch {
    // ignore write errors
  }
};
const timerSlice = createSlice({
  name: 'timer',
  initialState: loadState() || initialState,
  reducers: {
    startTimer: (state) => {
      state.startTime = Date.now();
      state.pausedTime = null;
      state.isActive = true;
      saveState(state); // зберігаємо стан таймера в localStorage
    },
    stopTimer: (state) => {
      state.isActive = false;
      saveState(state);
    },
    pauseTimer: (state) => {
      state.isActive = false;
      state.pausedTime = Date.now();
      saveState(state);
    },
    resumeTimer: (state) => {
      state.isActive = true;
      if (state.pausedTime) {
        const now = Date.now();
        const pausedDuration = now - state.pausedTime;
        state.startTime = state.startTime + pausedDuration;
        state.pausedTime = null;
        saveState(state);
      }
    },
    resetTimer: (state) => {
      state.startTime = null;
      state.pausedTime = null;
      state.elapsedTime = 0;
      state.isActive = false;
      saveState(state);
    },
    updateElapsedTime: (state) => {
      if (state.startTime && state.isActive) {
        const now = Date.now();
        const difference = now - state.startTime;
        state.elapsedTime = state.elapsedTime + difference;
        state.startTime = now;
        saveState(state);
      }
    },
    extraReducers: (builder) => {
      builder.addMatcher(
        (action) => action.type === 'timer/updateElapsedTime',
        (state) => {
          saveState(state); // зберігаємо стан таймера в localStorage після кожного оновлення
        }
      );
    },
  },
});

export const {
  startTimer,
  stopTimer,
  pauseTimer,
  resumeTimer,
  resetTimer,
  updateElapsedTime,
} = timerSlice.actions;

export default timerSlice.reducer;