import { createSlice } from '@reduxjs/toolkit';
import { getUserProfileThunk, updateUserProfileThunk } from './profileActions';

const initialState = {
  username: null,
  first_name: null,
  last_name: null,
  email: null,
  working_telegram: null,
  personal_telegram: null,
  phone_number: null,
  date_joined: null,
  country: null,
  payment_method:null,
  photo:null,
  loading: false,
  error: null,
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getUserProfileThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getUserProfileThunk.fulfilled, (state, action) => {
        state.username = action.payload.username;
        state.first_name = action.payload.first_name;
        state.last_name = action.payload.last_name;
        state.email = action.payload.email;
        state.working_telegram = action.payload.working_telegram;
        state.personal_telegram = action.payload.personal_telegram;
        state.phone_number = action.payload.phone_number;
        state.date_joined = action.payload.date_joined;
        state.country = action.payload.country;
        state.payment_method = action.payload.payment_method;
        state.photo = action.payload.photo;
        state.loading = false;
      })
      .addCase(getUserProfileThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(updateUserProfileThunk.pending, state => {
        state.loading = true;
      })
      .addCase(updateUserProfileThunk.fulfilled, (state, action) => {
        state.username = action.payload.username;
        state.first_name = action.payload.first_name;
        state.last_name = action.payload.last_name;
        state.email = action.payload.email;
        state.working_telegram = action.payload.working_telegram;
        state.personal_telegram = action.payload.personal_telegram;
        state.phone_number = action.payload.phone_number;
        state.date_joined = action.payload.date_joined;
        state.country = action.payload.country;
        state.photo = action.payload.photo;
        state.loading = false;
      })
      .addCase(updateUserProfileThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default profileSlice.reducer;
