import {
  DetailsBlock,
  DetailsItem,
  DetailsItemId,
  DetailsList,
  DetailsSpan,
  DetailsSpanPending,
  DetailsSpanUrgent,
  DetailsTicketChatConteiner,
  ResponsibilityBlock,
  MainText,
  SelectDetails,
  DetailsItemOption,
  DetailsOption,
  ColorBlock,
  DetailsUser,
  UserBlock,
  DetailsButton,
  DetailsFlexBlock,
  DetailsButtonFollower,
  RequesterBlock,
  MainRequesterBlock,
  UserNameBlock,
  UserName,
  UserEmail,
  Avatar,
  UserInfo,
} from './DetailsTicketChat.styled';

const DetailsTicketChat = () => {
  return (
    <DetailsTicketChatConteiner>
      <DetailsBlock>
        <MainText>Details</MainText>
        <DetailsList>
          <DetailsItem>
            Ticket ID: <DetailsItemId>GUERY6</DetailsItemId>
          </DetailsItem>
          <DetailsItem>
            Created: <DetailsSpan>7 feb 2024</DetailsSpan>
          </DetailsItem>
          <DetailsItem>
            Last message: <DetailsSpan>8 feb 2024</DetailsSpan>
          </DetailsItem>
          <DetailsItem>
            Status: <DetailsSpanPending>PENDING</DetailsSpanPending>
          </DetailsItem>
          <DetailsItem>
            Priority: <DetailsSpanUrgent>Urgent</DetailsSpanUrgent>
          </DetailsItem>
          <DetailsItem>
            Source: <DetailsItemId>Chat</DetailsItemId>
          </DetailsItem>
        </DetailsList>
      </DetailsBlock>
      <DetailsButton>+ Add tag</DetailsButton>
      <ResponsibilityBlock>
        <MainText>Responsibility</MainText>
        <DetailsList>
          <DetailsItemOption>
            Team:
            <SelectDetails>
              <DetailsOption>
                <ColorBlock />
                Cat VIP Payment
              </DetailsOption>
            </SelectDetails>
          </DetailsItemOption>
          <DetailsItemOption>
            Agent:
            <SelectDetails>
              <DetailsOption>
                <ColorBlock />
                Steven
              </DetailsOption>
            </SelectDetails>
          </DetailsItemOption>
          <DetailsItemOption>
            Followers:
            <DetailsFlexBlock>
              <UserBlock>
                <DetailsUser>Steven</DetailsUser>
                <DetailsUser>Harryson</DetailsUser>
                <DetailsUser>Harry</DetailsUser>
                <DetailsUser>Steven</DetailsUser>
                <DetailsUser>Steven</DetailsUser>
                <DetailsUser>Steven</DetailsUser>
              </UserBlock>
              <DetailsButtonFollower>+ Add Follower</DetailsButtonFollower>
            </DetailsFlexBlock>
          </DetailsItemOption>
        </DetailsList>
      </ResponsibilityBlock>
      <RequesterBlock>
        <MainRequesterBlock>
          <MainText>Responsibility</MainText>
          <DetailsButton>Change</DetailsButton>
        </MainRequesterBlock>
        <UserInfo>
          <Avatar />
          <UserNameBlock>
            <UserName>Артём Ковальцев</UserName>
            <UserEmail>artemkov982@gmail.com</UserEmail>
          </UserNameBlock>
        </UserInfo>
      </RequesterBlock>
    </DetailsTicketChatConteiner>
  );
};

export default DetailsTicketChat;
