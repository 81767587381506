import {
  MainTextQaSideBar,
  QaSideBarConteiner,
  QaSideBarItem,
  QaSideBarList,
  QaSideBarImg,
  QaSideBarLink,
} from './QaSideBar.styled';
import UnionList from '../../../images/UnionList.png';

const QaSideBar = () => {
  return (
    <QaSideBarConteiner>
      <QaSideBarList>
        <QaSideBarItem>
          <QaSideBarLink to='/QaPage'>
            <QaSideBarImg src={UnionList} />
            <MainTextQaSideBar>Chats</MainTextQaSideBar>
          </QaSideBarLink>
        </QaSideBarItem>
        <QaSideBarItem>
          <QaSideBarLink to='/QaArchive'>
            <QaSideBarImg src={UnionList} />
            <MainTextQaSideBar>Archive</MainTextQaSideBar>
          </QaSideBarLink>
        </QaSideBarItem>
        <QaSideBarItem>
          <QaSideBarImg src={UnionList} />
          <MainTextQaSideBar>Tickets</MainTextQaSideBar>
        </QaSideBarItem>
        <QaSideBarItem>
          <QaSideBarImg src={UnionList} />
          <MainTextQaSideBar>Activity</MainTextQaSideBar>
        </QaSideBarItem>
      </QaSideBarList>
    </QaSideBarConteiner>
  );
};

export default QaSideBar;
