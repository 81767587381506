import styled from 'styled-components';
import { PiTelegramLogo } from 'react-icons/pi';
import { IoShield } from 'react-icons/io5';

export const MainTicketChatConteiner = styled.div`
  margin-left: 112px;
  border-right: 1px solid #dfdaeb;
  width: 1450px;
  height: 100%;
  position: fixed;
`;

export const MessageConteiner = styled.div`
  width: 1182px;
  height: 600px;
`;

export const MainTextTicketChat = styled.h2`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;

  background: #1eaf69;
  border-radius: 11px 11px 0px 0px;

  padding: 10px;

  color: #ffffff;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 26px;
  height: 12px;
`;

export const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #4caf50;
  }

  &:checked + span:before {
    transform: translateX(10px);
  }
`;

export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 12px;

  margin-right: 5px;

  &:before {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

export const SwitcherBlock = styled.div`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;

  color: #0a1019;

  display: flex;

  position: absolute;
  left: 370px;
  bottom: 390px;
`;

export const TextAreaQa = styled.textarea`
  width: 742px;
  height: 130px;
  resize: none;
  position: absolute;
  top: 500px;
  left: 340px;
  background: #ffffff;
  box-shadow: 0px 2px 22.3px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  padding: 8px;
  padding-bottom: 65px;
  padding-top: 70px;
  border: 1px;
`;

export const TextAreaToolsConteiner = styled.div`
  position: absolute;
  top: 730px;
  left: 360px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 700px;
`;

export const TagBlock = styled.div``;

export const SubmitButton = styled.button`
  width: 49px;
  height: 20px;

  box-shadow: 0px 2px 22.3px rgba(0, 0, 0, 0.16);
  background: #0a1019;
  border-radius: 5px;

  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;

  color: #ffffff;

  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const TagButton = styled.button`
  width: 90px;
  height: 20px;
  background: transparent;
  border: 0;

  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 14px;

  color: #0a1019;

  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const TextBlock = styled.div`
  width: 742px;
  height: 200px;
  position: absolute;
  left: 347px;
  top: 420px;

  border: 1px solid #dfdaeb;
  border-radius: 10px;
`;

export const Telegram = styled(PiTelegramLogo)`
  color: #ffffff;
  width: 9px;
  height: 8px;
  margin-left: 5px;
`;

export const Shield = styled(IoShield)`
  color: #0a1019;
  width: 13px;
  height: 11px;
  margin-left: 5px;
`;
