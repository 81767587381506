import { createSlice } from '@reduxjs/toolkit';
import { getCashbackThunk, getSearchCashbackThunk } from './cashbackAction';

const initialState = {
  data: null,
};

const cashbackSlice = createSlice({
  name: 'cashback',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getCashbackThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getCashbackThunk.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getCashbackThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getSearchCashbackThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getSearchCashbackThunk.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(getSearchCashbackThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default cashbackSlice.reducer;