import styled from 'styled-components';
import { CiSearch } from 'react-icons/ci';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  height: 55px;
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ListHeader = styled.ul`
  margin-left: 30px;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
`;

export const ItemListHeader = styled.li`
  &:not(:last-child) {
    margin-right: 15px;
  }
  &:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 66px;
    height: 23px;
    border-radius: 2px;
  }
`;

export const SearchContainer = styled.div`
  height: 27px;
  margin-right: 50px;
  display: flex;
  align-items: center;
  border: 1px solid #cacaca;
`;

export const SearchIcon = styled(CiSearch)`
  width: 24px;
  height: 19px;
  margin-right: 15px;
  /* color: ${props => (props.switcher ? '#CACACA' : '#000000')}; */
  color: #cacaca;
`;

export const SearchInput = styled.input`
  border: none;
  outline: none;
  background-color: ${props => (props.switcher ? '#2E2E2E' : '#fff')};
  color: ${props => (props.switcher ? '#CACACA' : '#000000')};
`;

export const StyledNav = styled(NavLink)`
 
  text-decoration: none;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #7b7b7b;
  &.active {
    color: #1eaf69; 
  }
`;
export const StyledNavCreate = styled(NavLink)`
 
  text-decoration: none;
  font-family: 'Inter';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFF;
 
`;
