import styled from 'styled-components';


export const QaWorkPlaceConteiner = styled.div`

`;

export const MainQaConteiner = styled.div`
    display: flex;
`;

