import React, { useState } from 'react';
import Union from '../../../images/Union.png';
import {
  HeaderQaConteiner,
  HeaderQaMainItem,
  HeaderQaMainText,
  HeaderQaText,
  ImgConteiner,
  Item,
  MainQaImg,
  NavLinkStyled,
  TextQaConteiner,
} from './HeaderQaArchive.styled';

const HeaderQaArchive = ({setChangePage, setChangeItem, changeItem}) => {

  const onClickButton = item => {
    setChangeItem(item);
  };

  const onChangePage = () => {
    setChangePage(true)
  }

  console.log(changeItem);

  return (
    <HeaderQaConteiner>
      <ImgConteiner onClick={() => onChangePage()}>
        <NavLinkStyled to="/">
          <MainQaImg src={Union} />
        </NavLinkStyled>
      </ImgConteiner>
      <TextQaConteiner>
        <HeaderQaText>GDesk</HeaderQaText>
      </TextQaConteiner>
      <HeaderQaMainItem>
        <HeaderQaMainText>All recent tickets</HeaderQaMainText>
      </HeaderQaMainItem>
    </HeaderQaConteiner>
  );
};

export default HeaderQaArchive;
