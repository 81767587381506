import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCashback, getSearchCashback } from 'services/API';

export const getCashbackThunk = createAsyncThunk(
  'cashback/getAll',
  async ({token, page}, thunkAPI) => {
    try {
      const response = await getCashback(token, page);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getSearchCashbackThunk = createAsyncThunk(
  'cashback/postAll',
  async ({token, page, userId}, thunkAPI) => {
    try {
      const response = await getSearchCashback(token, page, userId);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
