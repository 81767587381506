import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getDatBaseArticle,
  getDataBaseNode,
  postDatBaseArticlePhoto,
  deleteDataBaseNode,
  postDataBaseNode,
  putDataBaseNode,
  postDatBaseArticle,
  putDatBaseArticle,
  deleteDatBaseArticle,
  deleteDatBaseArticlePhoto,
} from 'services/API';

export const getDataBaseNodeThunk = createAsyncThunk(
  'knowledgebase/getDatBaseNode',
  async ({token, id}, thunkAPI) => {
    try {

      const response = await getDataBaseNode(token, id);
      return response;
    } catch (e) {
      console.error('Error:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const postDataBaseNodeThunk = createAsyncThunk(
  'knowledgebase/postDatBaseNode',
  async ({token, neededData}, thunkAPI) => {
    console.log(neededData)
    try {
      const response = await postDataBaseNode(token, neededData);
      return response;
    } catch (e) {
      console.error('Error:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const putDataBaseNodeThunk = createAsyncThunk(
  'knowledgebase/putDatBaseNode',
  async ({token, neededData}, thunkAPI) => {
    console.log(neededData)
    try {
      const response = await putDataBaseNode(token, neededData);
      return response;
    } catch (e) {
      console.error('Error:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteDataBaseNodeThunk = createAsyncThunk(
  'knowledgebase/deleteDatBaseNode',
  async ({token, id}, thunkAPI) => {
    try {

      const response = await deleteDataBaseNode(token, id);
      return response;
    } catch (e) {
      console.error('Error:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);


export const getDatBaseArticleThunk = createAsyncThunk(
  'knowledgebase/getDatBaseArticle',
  async ({ token, id }, thunkAPI) => {
    try {

      const response = await getDatBaseArticle(token, id);
      return response;
    } catch (e) {
      console.error('Error:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const postDatBaseArticleThunk = createAsyncThunk(
  'knowledgebase/postDatBaseArticle',
  async ({ token, neededData }, thunkAPI) => {
    try {

      const response = await postDatBaseArticle(token, neededData);
      return response;
    } catch (e) {
      console.error('Error:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const putDatBaseArticleThunk = createAsyncThunk(
  'knowledgebase/putDatBaseArticle',
  async ({ token, neededData }, thunkAPI) => {
    try {

      const response = await putDatBaseArticle(token, neededData);
      return response;
    } catch (e) {
      console.error('Error:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteDataBaseArticleThunk = createAsyncThunk(
  'knowledgebase/deleteDatBaseNode',
  async ({token, id}, thunkAPI) => {
    try {

      const response = await deleteDatBaseArticle(token, id);
      return response;
    } catch (e) {
      console.error('Error:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);


export const postDatBaseArticlePhotoThunk = createAsyncThunk(
  'knowledgebase/putDatBaseNode',
  async ({token, formData}, thunkAPI) => {
  
    try {
      const response = await postDatBaseArticlePhoto(token, formData);
      return response;
    } catch (e) {
      console.error('Error:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const deleteDatBaseArticlePhotoThunk = createAsyncThunk(
  'knowledgebase/deleteDatBaseArticlePhoto',
  async ({token, article_id, image_id}, thunkAPI) => {
    try {

      const response = await deleteDatBaseArticlePhoto(token, article_id, image_id);
      return response;
    } catch (e) {
      console.error('Error:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);