import { useEffect, useState } from 'react';
import {
  FrodMainSpan,
  FrodMainText,
  FrodSelect,
  FrodSelectForm,
  FrodSelectFormButton,
  FrodSelectFormLabel,
  FrodSubSpan,
  FrodSubText,
  FrodOption,
} from './FrodSelectPage.styled';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessToken,
  selectFraudProjects,
  selectUserUsername,
  selectUserConfig,
  selectUserType,
  selectSwitcher,
} from '../../../redux/selectors';
import { getAntifraudShiftThunk, postAntifraudShiftThunk } from '../../../redux/fraud/fraudActions';

const FrodSelectPage = ({ onConfirm }) => {
  const proj = useSelector(selectFraudProjects);
  const token = useSelector(selectAccessToken);
  const user = useSelector(selectUserConfig);
  const userName = useSelector(selectUserUsername)
  const utype = useSelector(selectUserType);
  const switcher = useSelector(selectSwitcher);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAntifraudShiftThunk(token))
    if(utype === 'tl'){
      const shiftData = {
        project: "Cat",
        shift_type: "requests"
      }
      dispatch(postAntifraudShiftThunk({ shiftData, token }));
    }
  },[])
  

  const FrodShift = [
    { name: 'Requests' },
    { name: 'Affiliate Requests' },
    { name: 'Documents' },
    { name: 'Support' },
  ];

  const RequestTypes = [ { name: 'Regular' },{ name: 'VIP' }];

  const [selectedProject, setSelectedProject] = useState('');
  const [selectedShift, setSelectedShift] = useState('');
  const [selectedRequest, setSelectedRequest] = useState('');



  const handleConfirm = () => {
    const shiftData = {
      project: selectedProject.replace(/\s+/g, '_'),
      shift_type: selectedShift.toLocaleLowerCase().replace(/\s+/g, '_')
    }

    if (selectedRequest !== '') {
      shiftData.request_type = selectedRequest.toLocaleLowerCase().replace(/\s+/g, '_');
    }

    dispatch(postAntifraudShiftThunk({ shiftData, token }));
    
  };

  return (
    <>
      <FrodMainText switcher={switcher}>
        Приветствую вас, <FrodMainSpan>{userName}!</FrodMainSpan>
      </FrodMainText>
      <FrodSubText>
        Для начала работы, вам нужно выбрать <FrodSubSpan>проект</FrodSubSpan> и
        тип <FrodSubSpan>задач</FrodSubSpan>
      </FrodSubText>

      <FrodSelectForm>
        <FrodSelectFormLabel>Project</FrodSelectFormLabel>
        <FrodSelect
          value={selectedProject}
          onChange={e => setSelectedProject(e.target.value)}
        >
          <FrodOption value="">Select a project</FrodOption>
          {proj && proj.map(proj => {
            return <FrodOption key={proj}>{proj}</FrodOption>;
          })}
        </FrodSelect>
        <FrodSelectFormLabel>Shift type</FrodSelectFormLabel>
        <FrodSelect
          value={selectedShift}
          onChange={e => setSelectedShift(e.target.value)}
        >
          <FrodOption value="">Select a shift type</FrodOption>
          {FrodShift.map(({ name }) => (
            <FrodOption key={name}>{name}</FrodOption>
          ))}
        </FrodSelect>

        {user.can_swap_vip && selectedShift === 'Requests' ? (
          <>
            <FrodSelectFormLabel>Request Types</FrodSelectFormLabel>
            <FrodSelect
              value={selectedRequest}
              onChange={e => setSelectedRequest(e.target.value)}
            >
              {RequestTypes.map(({ name }) => {
                return <FrodOption key={name}>{name}</FrodOption>;
              })}
            </FrodSelect>
          </>
        ) : null}
        <FrodSelectFormButton type="button" onClick={handleConfirm}>
          Подтвердить
        </FrodSelectFormButton>
      </FrodSelectForm>
    </>
  );
};

export default FrodSelectPage;
