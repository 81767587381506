import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  TextSideBarDataBase,
  SideBarListContainer,
  TextContainer,
  DropDownList,
  DropDownItem,
  TextContainerItem,
  HoverButton,
  Trash,
  SideBarCreateButton,
  HoverButtonTwo,
  Article,
  Edit,
  Done,
  AddFolder,
} from './SideBarDataBase.styled';
import {
  selectNodeDataBase,
  selectAccessToken,
  selectUserType,
  selectSwitcher,
} from '../../../redux/selectors';
import {
  deleteDataBaseArticleThunk,
  deleteDataBaseNodeThunk,
  getDataBaseNodeThunk,
  postDatBaseArticleThunk,
  postDataBaseNodeThunk,
  putDataBaseNodeThunk,
} from '../../../redux/database/databaseActions';
import { useParams } from 'react-router-dom';
import { MutatingDots } from 'react-loader-spinner';
import CreateProjModal from '../ProjectPage/CreateProjModal/CreateProjModal';
import {
  CreateButtonModal,
  Cross,
  ModalHeadConatiner,
  ProjInput,
  ProjLabel,
} from '../ProjectPage/ProjectPage.styled';
import { ModalMainText } from 'components/TagsModal/TagsModal.styled';

const SideBarDataBase = ({ onValueChange }) => {
  const dispatch = useDispatch();
  const token = useSelector(selectAccessToken);
  const uNode = useSelector(selectNodeDataBase);
  const [mainNode, setMainNode] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [openNodes, setOpenNodes] = useState({});
  const loading = useSelector(state => state.database.loading);
  const { id } = useParams();
  const [hoveredNodeId, setHoveredNodeId] = useState(null);
  const [hoveredArticleId, setHoveredArticleId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [nodeName, setNodeName] = useState();
  const [currentRenameId, setCurrentRenameId] = useState();
  const utype = useSelector(selectUserType);
  const switcher = useSelector(selectSwitcher);

  const handleDropDownToggle = nodeId => {
    setOpenNodes(prevState => ({
      ...prevState,
      [nodeId]: !prevState[nodeId],
    }));
    if (nodeId !== selectedNodeId) {
      setSelectedNodeId(prevNodeId => (prevNodeId === nodeId ? null : nodeId));
    }
  };

  useEffect(() => {
    dispatch(getDataBaseNodeThunk({ token, id }));
  }, []);

  useEffect(() => {
    if (selectedNodeId !== null) {
      dispatch(getDataBaseNodeThunk({ token, id: selectedNodeId }));
    }
  }, [selectedNodeId]);

  useEffect(() => {
    if (uNode) {
      if (selectedNodeId === null) {
        setMainNode(uNode);
      } else {
        const updatedNodes = updateNodes(
          mainNode?.nodes,
          selectedNodeId,
          uNode
        );
        setMainNode(prevMainNode => ({ ...prevMainNode, nodes: updatedNodes }));
      }
    }
  }, [uNode]);

  const updateNodes = (nodes, targetId, uNode) => {
    return nodes.map(node => {
      if (node.id === targetId) {
        return { ...node, subNodes: uNode };
      } else if (node.subNodes && node.subNodes.nodes) {
        const updatedSubNodes = updateNodes(
          node.subNodes.nodes,
          targetId,
          uNode
        );
        return {
          ...node,
          subNodes: { ...node.subNodes, nodes: updatedSubNodes },
        };
      }
      return node;
    });
  };

  const handleMouseEnter = nodeId => setHoveredNodeId(nodeId);
  const handleMouseLeave = () => setHoveredNodeId(null);

  const handleMouseEnterArticle = articleId => setHoveredArticleId(articleId);
  const handleMouseLeaveArticle = () => setHoveredArticleId(null);

  const deleteNode = async nodeId => {
    const response = await dispatch(
      deleteDataBaseNodeThunk({ token, id: nodeId })
    );

    if (response.meta.requestStatus === 'fulfilled') {
      setOpenNodes({});
      setSelectedNodeId(null);
      dispatch(getDataBaseNodeThunk({ token, id }));
    }
  };

  const deleteArticle = async articleId => {
    const response = await dispatch(
      deleteDataBaseArticleThunk({ token, id: articleId })
    );

    if (response.meta.requestStatus === 'fulfilled') {
      onValueChange();
      setOpenNodes({});
      setSelectedNodeId(null);
      dispatch(getDataBaseNodeThunk({ token, id }));
    }
  };

  const createNode = async () => {
    const need = {
      title: 'New node',
      subtitle: 'New subtitle',
      parent_node: id,
    };
    const response = await dispatch(
      postDataBaseNodeThunk({ token, neededData: need })
    );
    if (response.meta.requestStatus === 'fulfilled') {
      setOpenNodes({});
      setSelectedNodeId(null);
      dispatch(getDataBaseNodeThunk({ token, id }));
    }
  };

  const createInNode = async currentId => {
    const need = {
      title: 'New node',
      subtitle: 'New subtitle',
      parent_node: currentId,
    };
    const response = await dispatch(
      postDataBaseNodeThunk({ token, neededData: need })
    );
    if (response.meta.requestStatus === 'fulfilled') {
      setOpenNodes({});
      setSelectedNodeId(null);
      dispatch(getDataBaseNodeThunk({ token, currentId }));
    }
  };

  const createArticle = async currentId => {
    const need = {
      text: 'New text',
      title: 'New title',
      subtitle: 'New subtitle',
      parent_node: currentId,
    };

    const response = await dispatch(
      postDatBaseArticleThunk({ token, neededData: need })
    );
    if (response.meta.requestStatus === 'fulfilled') {
      setOpenNodes({});
      setSelectedNodeId(null);
      dispatch(getDataBaseNodeThunk({ token, id }));
    }
  };

  const rename = async () => {
    const need = {
      title: nodeName,
      id: currentRenameId,
    };
    const response = await dispatch(
      putDataBaseNodeThunk({ token, neededData: need })
    );
    if (response.meta.requestStatus === 'fulfilled') {
      setOpenNodes({});
      handleCloseModal();
      setSelectedNodeId(null);
      dispatch(getDataBaseNodeThunk({ token, id }));
    }
  };

  const handleRename = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderNodes = nodes => {
    return (
      <>
        {nodes?.nodes?.map(node => (
          <TextContainerItem key={node?.id}>
            <SideBarListContainer
              key={node?.id}
              onMouseEnter={() => handleMouseEnter(node?.id)}
              onMouseLeave={() => handleMouseLeave(node?.id)}
            >
              <DropDownItem
                switcher={switcher}
                onClick={() => handleDropDownToggle(node?.id)}
              >
                <>{node?.title}</>
              </DropDownItem>
              {hoveredNodeId === node?.id &&
                openNodes[node?.id] === true &&
                utype === 'admin' && (
                  <div>
                    <HoverButton onClick={() => deleteNode(node?.id)}>
                      <Trash />
                    </HoverButton>
                    <HoverButton onClick={() => createArticle(node?.id)}>
                      <Article />
                    </HoverButton>
                    <HoverButton
                      onClick={() => {
                        handleRename();
                        setCurrentRenameId(hoveredNodeId);
                      }}
                    >
                      <Edit />
                    </HoverButton>
                    <HoverButton
                      onClick={() => {
                        createInNode(node?.id);
                      }}
                    >
                      <AddFolder />
                    </HoverButton>
                  </div>
                )}
            </SideBarListContainer>
            {openNodes[node?.id] && node?.subNodes && (
              <DropDownList switcher={switcher}>
                {renderNodes(node?.subNodes)}
              </DropDownList>
            )}
          </TextContainerItem>
        ))}
        {nodes?.articles && (
          <div>
            {nodes?.articles?.map((article, index) => (
              <div key={index}>
                <SideBarListContainer
                  onClick={() => onValueChange(article?.id)}
                  onMouseEnter={() => handleMouseEnterArticle(article?.id)}
                  onMouseLeave={() => handleMouseLeaveArticle(article?.id)}
                >
                  {article?.title}
                  {hoveredArticleId === article?.id && utype === 'admin' && (
                    <div>
                      <HoverButton
                        onClick={e => {
                          e.stopPropagation();
                          deleteArticle(article?.id);
                        }}
                      >
                        <Trash />
                      </HoverButton>
                    </div>
                  )}
                </SideBarListContainer>
              </div>
            ))}
          </div>
        )}
        <CreateProjModal isOpen={isModalOpen} onClose={handleCloseModal}>
          <ModalHeadConatiner>
            <ModalMainText>Название папки</ModalMainText>
            <Cross onClick={() => handleCloseModal()} />
          </ModalHeadConatiner>
          <ProjLabel>Новое имя папки</ProjLabel>
          <ProjInput type="text" onChange={e => setNodeName(e.target.value)} />
          <CreateButtonModal onClick={rename}>Переименовать</CreateButtonModal>
        </CreateProjModal>
      </>
    );
  };

  return (
    <Container>
      <TextContainer>
        <TextSideBarDataBase switcher={switcher}>Menu</TextSideBarDataBase>
      </TextContainer>
      {loading ? (
        <MutatingDots
          height="100"
          width="100"
          color="#AFFFB7"
          secondaryColor="#AFFFB7"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      ) : (
        <>
          {utype === 'admin' ? (
            <SideBarCreateButton onClick={() => createNode()}>
              Создать папку
            </SideBarCreateButton>
          ) : (
            <></>
          )}

          {mainNode && mainNode.nodes && renderNodes(mainNode)}
        </>
      )}
    </Container>
  );
};

export default SideBarDataBase;
