import { createSlice } from '@reduxjs/toolkit';
import { deleteDataBaseNodeThunk, getDatBaseArticleThunk,putDatBaseArticleThunk,  getDataBaseNodeThunk } from './databaseActions';
const initialState = {
  nodes: null,
  article: null,
  text: null,
  images: [ {
    id: null,
    image: null,
  }],
  created_by: null,
  created_at: null,
  title: null,
  subtitle: null,
  loading: false,
  error: null,
};



const databaseSlice = createSlice({
  name: 'database',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getDataBaseNodeThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getDataBaseNodeThunk.fulfilled, (state, action) => {
        state.nodes = action.payload;
        state.loading = false;
      })
      .addCase(getDataBaseNodeThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getDatBaseArticleThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getDatBaseArticleThunk.fulfilled, (state, action) => {
        state.article = action.payload;
        state.text = action.payload.text;
        state.images = action.payload.images;
        state.created_by = action.payload.created_by;
        state.created_at = action.payload.created_at;
        state.title = action.payload.title;
        state.subtitle = action.payload.subtitle;
        state.loading = false;
      })
      .addCase(getDatBaseArticleThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(putDatBaseArticleThunk.pending, state=>{
        state.loading = true;
      })
      .addCase(putDatBaseArticleThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(putDatBaseArticleThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(deleteDataBaseNodeThunk.pending, state=>{
        state.loading = true;
      })
      .addCase(deleteDataBaseNodeThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(deleteDataBaseNodeThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
  },
});

export default databaseSlice.reducer;
