import QaWorkPlace from '../../components/QaPage/QaWorkPlace/QaWorkPlace';
import { QaPageConteiner } from './QaPage.styled';

const QaPage = ({setChangePage}) => {
  return (
    <QaPageConteiner>
      <QaWorkPlace setChangePage={setChangePage}/>
    </QaPageConteiner>
  );
};

export default QaPage;
