import React, { useEffect } from 'react';
import {
  FrodTimer,
  FrodWorkContainer,
  FrodWorkMainText,
  FrodWorkText,
} from './FrodDocuments.styled';
import { useDispatch, useSelector } from 'react-redux';
import { selectTimerElapsed,} from '../../../redux/selectors';
import { updateElapsedTime } from '../../../redux/timer/timerSlice';

const FrodDocuments = ({ project, shift }) => {
  const dispatch = useDispatch();
  const isElapsed = useSelector(selectTimerElapsed);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(updateElapsedTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, [dispatch]);

  const formattedTime = new Date(isElapsed).toISOString().substr(11, 8);
 
  
  return (
    <FrodWorkContainer>
      <FrodWorkMainText>
        <FrodWorkText>Current Project: {project}</FrodWorkText>
        <FrodWorkText>Shift Type: {shift}</FrodWorkText>
        <FrodTimer>{formattedTime}</FrodTimer>
      </FrodWorkMainText>
    </FrodWorkContainer>
  );
};

export default FrodDocuments;