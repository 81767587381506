export const getUser = (state) => state.auth.user;
export const isAuthenticated = (state) => state.auth.isAuthenticated;
export const selectIsRefreshing = (state) => state.auth.isRefreshing;
export const selectRefreshToken = (state) => state.auth.refreshToken;
export const selectAccessToken = (state) => state.auth.accessToken;

export const selectUserUsername = (state) => state.user.username;
export const selectUserBalance = (state) => state.user.balance;
export const selectUserType = (state) => state.user.type;
export const selectUserDepartment = (state) => state.user.department;
export const selectUserGrade = (state) => state.user.grade;
export const selectUserCurrency = (state) => state.user.currency;
export const selectUserConfig = (state) => state.user.config;
export const selectUserShift = (state) => state.user.shift;
export const selectUserIsUser = (state) => state.user.isUser;
export const selectUserisRequest = (state) => state.user.isRequest;
export const selectUserExpiredTime = (state) => state.user.expiredTime;
export const selectUserPhoto = (state) => state.user.photo;

export const selectUserUsernameProfile = (state) => state.profile.username;
export const selectUserFirstNameProfile = (state) => state.profile.first_name;
export const selectUserLastNameProfile = (state) => state.profile.last_name;
export const selectUserEmailProfile = (state) => state.profile.email;
export const selectUserWorkingTelegramlProfile = (state) => state.profile.working_telegram;
export const selectUserPersonalTelegramProfile = (state) => state.profile.personal_telegram;
export const selectUserPhoneNumberProfile = (state) => state.profile.phone_number;
export const selectUserСountryProfile = (state) => state.profile.country;
export const selectUserDateJoinedProfile = (state) => state.profile.date_joined;
export const selectUserPaymetMethodProfile = (state) => state.profile.payment_method;
export const selectUserPhotoProfile = (state) => state.profile.photo;
export const selectUserEscalade = (state) => state.user.process_escalade;

export const selectFraudProjects = (state) => state.fraud.projects;
export const selectFraudProject = (state) => state.fraud.project;
export const selectFraudShiftType = (state) => state.fraud.shift_type;
export const selectFraudRequestType = (state) => state.fraud.request_type;
export const selectFraudRequest = (state) => state.fraud.request;
export const selectFraudPK = (state) => state.fraud.pk;
export const selectFraudDecision = (state) => state.fraud.data.decision;
export const selectFraudComment = (state) => state.fraud.data.comment;
export const selectFraudAutoGetNext = (state) => state.fraud.data.auto_get_next;
export const selectFraudHolds = (state) => state.fraud.holds;
export const selectFraudStat = (state) => state.fraud.stat;
export const selectFraudStatTl = (state) => state.fraud.statTl;
export const selectFraudStatTeam = (state) => state.fraud.statSup;
export const selectFraudStatTeamPk = (state) => state.fraud.agentStat;
export const selectFraudLoading = (state) => state.fraud.loading

export const selectSchedule = (state) => state.schedule.schedule
export const selectShiftSchedule = (state) => state.schedule.shift
export const selectScheduleWorkerLunch = (state) => state.schedule.workerLunch
export const selectScheduleWorkerTbreak = (state) => state.schedule.workerTbreak
export const selectScheduleUpdateTable = (state) => state.schedule.table

export const selectPersonalShifts = (state) => state.schedule.personal_shifts
export const selectPersonalScheduleChangeData = (state) => state.schedule
export const selectPersonalTags = (state) => state.schedule.personal_tags

export const selectCashbacks = (state) => state.cashback.data

export const selectArchives = (state) => state.archive.data

export const selectTimerStart = (state) => state.timer.startTime
export const selectTimerElapsed = (state) => state.timer.elapsedTime
export const selectTimerActive = (state) => state.timer.isActive
export const selectTimerPaused = (state) => state.timer.isPaused

export const selectSwitcher = (state) => state.switcher.switcher

export const selectNodeDataBase = (state) => state.database.nodes;
export const selectArcticals  = (state) => state.database.article;
export const selectArcticalCreatedAt = (state) => state.database.created_at;
export const selectArcticalCreatedBy = (state) => state.database.created_by;
export const selectArcticalText = (state) => state.database.text;
export const selectArcticalTitle = (state) => state.database.title;
export const selectArcticalImages = (state) => state.database.images;