import HeaderQaArchive from 'components/QaArchivePage/HeaderQaArchive/HeaderQaArchive';
import QaSideBar from 'components/QaPage/QaSideBar/QaSideBar';
import { MainQaArchiveConteiner, QaArchivePageConteiner } from './QaArchivePage.styled';
import QaGDesk from 'components/QaArchivePage/QaGDesk/QaGDesk';
import QaArchiveMain from 'components/QaArchivePage/QaArchiveMain/QaArchiveMain';
const QaArchivePage = () => {
  return (
    <QaArchivePageConteiner>
      <HeaderQaArchive />
      <MainQaArchiveConteiner>
        <QaSideBar />
        <QaGDesk/>
        <QaArchiveMain/>
      </MainQaArchiveConteiner>
    </QaArchivePageConteiner>
  );
};

export default QaArchivePage;
