import styled from 'styled-components';

export const QaGDeskConteiner = styled.div`
  margin-left: 113px;
  border-right: 1px solid #dfdaeb;
  width: 280px;
  height: 100%;
  position: fixed;
`;

export const QaGDeskSearchWrap = styled.div`
margin-top: 17px;
width: 250px;
height: 30px;
border-radius: 5px;
border: 1px solid #DFDAEB ;
display: flex;
align-items: center;
margin-bottom: 17px;
`;

export const QaGDeskSearch = styled.input`
border: none;
font-family: 'Geologica';
font-size: 12px;
font-weight: 300;
line-height: 15px;
text-align: left;
outline: none;
`;

export const QaGDeskIcon = styled.img`
width: 10px;
height: 10px;
margin-left: 10px;
margin-right: 9px;
`;

export const QaGDeskTicketsText = styled.h3`
font-family: 'Geologica';
font-size: 12px;
font-weight: 500;
line-height: 15px;
text-align: left;
color: #1EAF69;
margin-bottom:15px;
margin-top:15px;
`;

export const QaGDeskTicketCon = styled.div`
margin-top:5px;
width: 250px;
height: 34px;
border-radius: 5px;
box-shadow: 0px 0px 4.8px 0px #0A10192B;
display: flex;
align-items: center;
justify-content: space-between;
`;

export const QaGDeskTicketName = styled.h3`
font-family: 'Geologica';
font-size: 12px;
font-weight: 300;
line-height: 15px;
text-align: left;
color: #0A1019;
margin-left: 10px;
`;

export const QaGDeskTicketNum = styled.div`
width: 24px;
height: 24px;
background-color: #F2F2F2;
border-radius: 9px;
display: flex;
align-items: center;
justify-content: center;
font-family: 'Geologica';
font-size: 12px;
font-weight: 300;
line-height: 15px;
text-align: center;
color: #1EAF69;
margin-right:5px;
`;