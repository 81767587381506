import styled from 'styled-components';
import { FaRegTrashCan } from 'react-icons/fa6';
import { MdOutlineArticle } from 'react-icons/md';
import { MdEdit } from 'react-icons/md';
import { MdOutlineDoneOutline } from 'react-icons/md';
import { RiFolderAddLine } from "react-icons/ri";

export const Container = styled.div`
  min-width: 327px;
  border-right: 2px solid #dbdbdb;
  height: 100vh;
  overflow-y: auto;
`;

export const TextSideBarDataBase = styled.h2`
  margin: 0;
  font-family: 'DM Sans';
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 20px;
  padding-top: 25px;
  color: ${props => (props.switcher ? '#D8D8D8' : '#242424')};
`;

export const SideBarListContainer = styled.div`
  position: relative;
  padding-left: 20px;
  padding-top: 11px;
  cursor: pointer;
`;

export const TextContainer = styled.div`
  height: 60px;
  border-bottom: 2px solid #dbdbdb;
`;

export const DropDownList = styled.ul`
  margin: 0;
  color: ${props => (props.switcher ? '#D8D8D8' : '#242424')};
`;

export const DropDownItem = styled.li`
  color: ${props => (props.switcher ? '#D8D8D8' : '#242424')};
`;

export const HoverButton = styled.button`
  display: none;
  position: absolute;
  top: 5px;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  padding: 2px 6px; /* Увеличиваем отступы, чтобы кнопки были более просторными */
  cursor: pointer;

  /* Первая кнопка будет смещена на 0px от правого края */
  right: 0;

  /* Вторая кнопка будет смещена на 30px от правого края */
  &:nth-child(2) {
    right: 35px;
  }

  &:nth-child(3) {
    right: 70px;
  }

  &:nth-child(4) {
    right: 105px;
  }
`;

export const TextContainerItem = styled.div`
  position: relative;
  &:hover ${HoverButton} {
    display: block;
  }
`;

export const Trash = styled(FaRegTrashCan)`
  width: 15px;
  height: 15px;
`;

export const AddFolder = styled(RiFolderAddLine)`
  width: 15px;
  height: 15px;
`;

export const Article = styled(MdOutlineArticle)`
  width: 15px;
  height: 15px;
`;
export const Edit = styled(MdEdit)`
  width: 15px;
  height: 15px;
`;
export const Done = styled(MdOutlineDoneOutline)`
  width: 15px;
  height: 15px;
`;

export const SideBarCreateButton = styled.button`
  background-color: #1eaf69;
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px;
  border: none;
  transition: background-color 0.3s; /* Добавляем плавное переходное свойство */
  cursor: pointer;
  margin-top: 10px;
  margin-left: 20px;

  &:hover {
    background-color: #1f803c;
  }
`;
