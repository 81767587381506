import InfoBar from './InfoBar/InfoBar';
import {
  AgentContainer,
  FlexBlock,
  LinkArrow,
  LinkButton,
  MainStatisticsSpan,
  MainStatisticsText,
  StatisticAgentConteiner,
  StatisticDate,
  StatisticFilterConteiner,
  StatisticsSubText,
  SubContainer,
  ToggleLabel,
  ToggleSwitch,
  UpdateStat,
} from './StatisticAgent.styled';
import {
  selectAccessToken,
  selectFraudStat,
  selectFraudStatTeam,
  selectFraudStatTeamPk,
  selectUserUsername,
  selectSwitcher,
  selectFraudLoading,
} from '../../../redux/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  getFraudPersonalStatisticsPkThunk,
  getFraudPersonalStatisticsThunk,
} from '../../../redux/fraud/fraudActions';
import { useNavigate, useParams } from 'react-router-dom';
import { Load } from 'components/App.styled';
import { MutatingDots } from 'react-loader-spinner';

const StatisticAgent = () => {
  const dispatch = useDispatch();
  const switcher = useSelector(selectSwitcher);
  const token = useSelector(selectAccessToken);
  const uname = useSelector(selectUserUsername);
  const uStat = useSelector(selectFraudStat);
  const loading = useSelector(selectFraudLoading);
  const uStatAgent = useSelector(selectFraudStatTeamPk);
  const { agent } = useParams();
  const { date } = useParams();
  const [todayValue, setTodayValue] = useState();
  const [weekValue, setWeekValue] = useState();
  const [monthValue, setMonthValue] = useState();
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const navigate = useNavigate();

  // console.log(uStatAgent?.week);

  useEffect(() => {
    if (uStatAgent === null) {
      uStat?.today?.map(stat => setTodayValue(stat));
      uStat?.week?.map(stat => setWeekValue(stat));
      uStat?.month?.map(stat => setMonthValue(stat));
    } else {
      uStatAgent?.today?.map(stat => setTodayValue(stat));
      uStatAgent?.week?.map(stat => setWeekValue(stat));
      uStatAgent?.month?.map(stat => setMonthValue(stat));
    }
  }, [uStat, uStatAgent]);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate().toString().padStart(2, '0');
    const monthDate = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const year = currentDate.getFullYear();
    return `${year}-${monthDate}-${day}`;
  };

  const [startDate, setStartDate] = useState(date || getCurrentDate());
  const [isToggleOn, setToggle] = useState(false);
  const dayType = isToggleOn ? 'night' : 'day ';

  const handleDateChange = event => {
    const selectedDate = event.target.value;
    setStartDate(selectedDate);
    console.log('Выбранная дата:', selectedDate);
  };

  const handleReturnDate = () => {
    const currentDate = getCurrentDate();
    setStartDate(currentDate);
    console.log('Текущая дата:', currentDate);
  };

  const handleToggleChange = () => {
    setToggle(!isToggleOn);
  };

  useEffect(() => {
    const currentDate = getCurrentDate();
    const fetchStatistics = () => {
      if (agent !== undefined) {
        dispatch(
          getFraudPersonalStatisticsPkThunk({
            token,
            agent,
            startDate,
            dayType,
          })
        );
      } else {
        dispatch(
          getFraudPersonalStatisticsThunk({ token, startDate, dayType })
        );
      }
    };

    fetchStatistics();
    setIsFirstLoad(false);

    if (currentDate === startDate) {
      const intervalId = setInterval(fetchStatistics, 10000);
      return () => clearInterval(intervalId);
    }
  }, [dispatch, agent, startDate, dayType, token]);

  return (
    <>
      {isFirstLoad && loading ? (
        <Load>
          <MutatingDots
            height="100"
            width="100"
            color="#AFFFB7"
            secondaryColor="#AFFFB7"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </Load>
      ) : (
        <StatisticAgentConteiner>
          {uStatAgent === null ? (
            <>
              {' '}
              <MainStatisticsText switcher={switcher}>
                Приветствую вас,{' '}
                <MainStatisticsSpan>{uname}!</MainStatisticsSpan>
              </MainStatisticsText>{' '}
              <StatisticFilterConteiner>
                <label htmlFor="date">Выберите дату:</label>
                <FlexBlock>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={startDate}
                    onChange={handleDateChange}
                  />
                  <ToggleSwitch>
                    <input
                      type="checkbox"
                      checked={isToggleOn}
                      onChange={handleToggleChange}
                    />
                    <span />
                  </ToggleSwitch>
                  <ToggleLabel>{isToggleOn ? 'Ночная' : 'Дневная'}</ToggleLabel>
                  <UpdateStat onClick={handleReturnDate} />
                </FlexBlock>
              </StatisticFilterConteiner>
            </>
          ) : (
            <>
              <LinkButton onClick={() => navigate(-1)}>
                <LinkArrow /> Back
              </LinkButton>
              <StatisticFilterConteiner>
                <label htmlFor="date">Выберите дату:</label>
                <FlexBlock>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={startDate}
                    onChange={handleDateChange}
                  />
                  <ToggleSwitch>
                    <input
                      type="checkbox"
                      checked={isToggleOn}
                      onChange={handleToggleChange}
                    />
                    <span />
                  </ToggleSwitch>
                  <ToggleLabel>{isToggleOn ? 'Ночная' : 'Дневная'}</ToggleLabel>
                  <UpdateStat onClick={handleReturnDate} />
                </FlexBlock>
              </StatisticFilterConteiner>
            </>
          )}
          <StatisticsSubText switcher={switcher}>Статистика</StatisticsSubText>
          <StatisticDate>{startDate}</StatisticDate>
          <AgentContainer>
            <SubContainer>
              <InfoBar
                week={`${
                  weekValue !== null
                    ? weekValue?.escalades +
                      weekValue?.approves +
                      weekValue?.declines
                    : '-'
                }`}
                day={`${
                  todayValue !== null
                    ? todayValue?.escalades +
                      todayValue?.approves +
                      todayValue?.declines
                    : '-'
                }`}
                month={`${
                  monthValue !== null
                    ? monthValue?.escalades +
                      monthValue?.approves +
                      monthValue?.declines
                    : '-'
                }`}
                header={'Кол-во обработанных заявок'}
              />

              <InfoBar
                week={weekValue?.escalades ? `${weekValue?.escalades}` : '0'}
                day={todayValue?.escalades ? `${todayValue?.escalades}` : '0'}
                month={monthValue?.escalades ? `${monthValue?.escalades}` : '0'}
                header={'Кол-во ескалейтов'}
              />
            </SubContainer>
            <SubContainer>
              <InfoBar
                week={weekValue?.approves ? `${weekValue?.approves}` : '0'}
                day={todayValue?.approves ? `${todayValue?.approves}` : '0'}
                month={monthValue?.approves ? `${monthValue?.approves}` : '0'}
                header={'Кол-во Апрув'}
              />
              <InfoBar
                week={weekValue?.declines ? `${weekValue?.declines}` : '0'}
                day={todayValue?.declines ? `${todayValue?.declines}` : '0'}
                month={monthValue?.declines ? `${monthValue?.declines}` : '0'}
                header={'Кол-во диклайнов'}
              />
            </SubContainer>
          </AgentContainer>
        </StatisticAgentConteiner>
      )}
    </>
  );
};

export default StatisticAgent;
