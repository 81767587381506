import { createAsyncThunk } from '@reduxjs/toolkit';
import { getUserProfile, postUserPaymentMethod,postChangeProfilePhoto } from 'services/API';
import { putUserProfile } from 'services/API';

export const getUserProfileThunk = createAsyncThunk(
  'profile/getUserProfile',
  async ( token,thunkAPI) => {
    try {
      const response = await getUserProfile(token);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateUserProfileThunk = createAsyncThunk(
  'profile/putUserProfile',
  async ({ token, userData }, thunkAPI) => {
    try {
      const response = await putUserProfile(token, userData);
      return response;
    } catch (e) {
      console.error('Error while updating user profile:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateUserPaymentMethodThunk = createAsyncThunk(
  'profile/postUserSalary',
  async({token,paymentMethod},thunkAPI) =>{
    try{
      const response = await postUserPaymentMethod(token,paymentMethod);
      return response;
    }catch(e) {
      console.error('Error while updating user paymentMethod:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateUserPhotoProfileThunk = createAsyncThunk(
  'profile/postChangeProfilePhoto',
  async ({ token, formData }, thunkAPI) => {
    try {
      const response = await postChangeProfilePhoto(formData, token);
      return response;
    } catch (e) {
      console.error('Error while updating user Profile Photo:', e.message);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
