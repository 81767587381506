import styled from 'styled-components';
import { RxAvatar } from 'react-icons/rx';
import { BsGeoAlt } from 'react-icons/bs';
import { FaRegClock } from 'react-icons/fa';

export const GeneralInfoConteiner = styled.div`
  margin-left: 1562px;
  width: 480px;
  height: 100%;
  position: fixed;
`;

export const GeneralInfoBlock = styled.div`
  margin-top: 19px;
  margin-left: 30px;
`;

export const UserBlock = styled.div``;

export const MainText = styled.h1`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  margin: 0;

  color: #0a1019;
`;

export const UserInfo = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const UserImg = styled.img``;

export const UserNameBlock = styled.div``;

export const UserName = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  margin: 0;

  color: #0a1019;
`;

export const UserEmail = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;

  margin: 0;

  color: #0a1019;
`;

export const Avatar = styled(RxAvatar)`
  width: 30px;
  height: 30px;

  margin-right: 10px;
`;

export const TimeGeolocationBlock = styled.div``;

export const GeolocationItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const Geolocation = styled(BsGeoAlt)`
  width: 12px;
  height: 12px;

  margin-right: 8px;
`;

export const Time = styled(FaRegClock)`
  width: 12px;
  height: 12px;

  margin-right: 8px;
`;

export const TimeItem = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const MainDataBlock = styled.div`
  margin-top: 20px;
  margin-left: 30px;
`;

export const MainDataList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const MainDataItem = styled.li``;

export const MainDataText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const MainDataSpan = styled.span`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #b7b0c7;
`;

export const PreChatFormBlock = styled.div`
  margin-top: 20px;
  margin-left: 30px;
`;

export const PreChatFormList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const PreChatFormItem = styled.li``;

export const PreChatFormText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const PreChatFormSpan = styled.span`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #b7b0c7;
`;

export const TechnicalDataBlock = styled.div`
  margin-top: 20px;
  margin-left: 30px;
`;

export const TechnicalDataList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const TechnicalDataItem = styled.li``;

export const TechnicalDataText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const TechnicalDataSpan = styled.span`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;

  color: #b7b0c7;
`;

export const RequesterTicketsBlock = styled.div`
  margin-top: 20px;
  margin-left: 30px;
`;

export const SecondMainText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const RequesterTicketsList = styled.ul`
  list-style: none;
  padding: 0;
`;

export const RequesterTicketsItem = styled.li`
  display: flex;
  align-items: center;
`;

export const ClosedBlock = styled.div`
  width: 43px;
  height: 10px;
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 6px;
  line-height: 8px;
  text-align: center;

  color: #ffffff;

  background: #8a8a8a;
  border-radius: 2px;
  transform: matrix(1, 0, 0, -1, 0, 0);

  margin-right: 5px;
`;

export const LinkRequesterTickets = styled.a`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  text-decoration-line: underline;

  color: #ae8cff;
`;

export const RequesterTicketsText = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  color: #b7b0c7;
`;
