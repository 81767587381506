import { useState, useRef, useEffect } from 'react';
import {
  DateInfo,
  IdInfo,
  List,
  PenButton,
  PercentItem,
  Progress,
  ProgressBar,
  StatInformationBlock,
  StatsPageItem,
  StatsPageListConteiner,
  StatusInfo,
  TimeInfo,
  UserImg,
  UserInfoBlock,
  UserName,
} from './SupportVip.styled';
import testImg from '../../../images/testImg.png';
import SupportMenu from '../SupportMenu/SupportMenu';

const SupportVip = () => {
  const [openModal, setOpenModal] = useState(false);
  const modalRef = useRef(null);

  const toggleModal = () => {
    setOpenModal(prevState => !prevState);
  };

  const handleClickOutside = event => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (openModal) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Удаляем обработчик при размонтировании компонента
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [openModal]);

  return (
    <>
      <StatsPageListConteiner>
        <List>
          <StatsPageItem>
            <UserInfoBlock>
              <UserImg src={testImg} />
              <UserName>Артём Ковальцев</UserName>
              <IdInfo>#2321565</IdInfo>
            </UserInfoBlock>
            <ProgressBar>
              <Progress percent={80} />
            </ProgressBar>
            <PercentItem>80%</PercentItem>
            <StatInformationBlock>
              {/* <StatusInfo>Завершён</StatusInfo> */}
              <DateInfo>23/04/2024 | 14:37</DateInfo>
              <TimeInfo>1 час 23 минуты</TimeInfo>
            </StatInformationBlock>
            <PenButton onClick={toggleModal} />
          </StatsPageItem>
        </List>
      </StatsPageListConteiner>
      {openModal && (
        <div ref={modalRef} >
          <SupportMenu />
        </div>
      )}
    </>
  );
};

export default SupportVip;
