import {
    MessageConteiner,
  QaMessageAreaConteiner,
  SendButton,
  Shield,
  TagBlock,
  TagButton,
  Telegram,
  TextAreaQa,
  TextAreaToolsConteiner,
} from './QaMessageArea.styled';

const QaMessageArea = () => {
  return (
    <QaMessageAreaConteiner>
        {/* <MessageConteiner>

        </MessageConteiner> */}
      <TextAreaQa />
      <TextAreaToolsConteiner>
        <TagBlock>
          <TagButton>
            <Shield />
            Add tag
          </TagButton>
        </TagBlock>
        <SendButton>
          Send
          <Telegram />
        </SendButton>
      </TextAreaToolsConteiner>
    </QaMessageAreaConteiner>
  );
};

export default QaMessageArea;
