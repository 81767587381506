import { createSlice } from '@reduxjs/toolkit';
import { getArchiveThunk } from './archiveAction';

const initialState = {
  data: null,
};

const archiveSlice = createSlice({
  name: 'archive',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getArchiveThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getArchiveThunk.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(getArchiveThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
  },
});

export default archiveSlice.reducer;