import {
  AddTagButton,
  CheckBoxConteiner,
  CheckBoxText,
  CheckInput,
  ClosedBlock,
  LinkRequesterTickets,
  MainTextQaTicketDask,
  QaTicketDaskConteiner,
  QaTicketDaskForm,
  QaTicketDaskInput,
  QaTicketDaskLabel,
  QaTicketDaskOption,
  QaTicketDaskSelect,
  QaTicketDaskSendButton,
  QaTicketDaskTextArea,
  QaTicketDaskTextForm,
  RequesterTicketsBlock,
  RequesterTicketsItem,
  RequesterTicketsList,
  RequesterTicketsText,
  SecondMainText,
  StyledCheckbox,
} from './QaTicketDask.styled';

const QaTicketDask = () => {
  return (
    <QaTicketDaskConteiner>
      <MainTextQaTicketDask>Create ticket in GDesk</MainTextQaTicketDask>
      <QaTicketDaskForm>
        <QaTicketDaskLabel>
          Ticket subject
          <QaTicketDaskInput />
        </QaTicketDaskLabel>
        <QaTicketDaskLabel>
          Customer’s name
          <QaTicketDaskInput />
        </QaTicketDaskLabel>
        <QaTicketDaskLabel>
          Customer’s email
          <QaTicketDaskInput />
        </QaTicketDaskLabel>
        <QaTicketDaskLabel>
          Team
          <QaTicketDaskSelect>
            <QaTicketDaskOption>Cat VIP Payment</QaTicketDaskOption>
          </QaTicketDaskSelect>
        </QaTicketDaskLabel>
        <QaTicketDaskLabel>
          Priority
          <QaTicketDaskSelect>
            <QaTicketDaskOption>Steven</QaTicketDaskOption>
          </QaTicketDaskSelect>
        </QaTicketDaskLabel>
        <QaTicketDaskLabel>
          Status
          <QaTicketDaskSelect>
            <QaTicketDaskOption>Open</QaTicketDaskOption>
          </QaTicketDaskSelect>
        </QaTicketDaskLabel>
        <AddTagButton>+ Add tag</AddTagButton>
        <QaTicketDaskLabel>
          Private note (optional)
          <QaTicketDaskTextArea />
        </QaTicketDaskLabel>
        <CheckBoxConteiner>
          <CheckInput type="checkbox" />
          <CheckBoxText>Send notification to the customer</CheckBoxText>
        </CheckBoxConteiner>
        <QaTicketDaskSendButton>Create ticket</QaTicketDaskSendButton>
        <QaTicketDaskTextForm>
          Ticket with chat transcript will be created. Customer will receive the
          transcript, too.
        </QaTicketDaskTextForm>
      </QaTicketDaskForm>
      {/* <RequesterTicketsBlock>
        <MainTextQaTicketDask>Requester tickets</MainTextQaTicketDask>
        <SecondMainText>Recent tickets</SecondMainText>
        <RequesterTicketsList>
          <RequesterTicketsItem>
            <ClosedBlock>CLOSED</ClosedBlock>
            <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
          </RequesterTicketsItem>
          <RequesterTicketsItem>
            <ClosedBlock>CLOSED</ClosedBlock>
            <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
          </RequesterTicketsItem>
          <RequesterTicketsItem>
            <ClosedBlock>CLOSED</ClosedBlock>
            <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
          </RequesterTicketsItem>
        </RequesterTicketsList>
        <SecondMainText>Archived tickets</SecondMainText>
        <RequesterTicketsText>
          <LinkRequesterTickets>Search Archive</LinkRequesterTickets> to view
          this requester’s archived tickets.
        </RequesterTicketsText>
      </RequesterTicketsBlock> */}
    </QaTicketDaskConteiner>
  );
};

export default QaTicketDask;
