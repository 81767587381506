import styled from 'styled-components';
import { LuDownload } from 'react-icons/lu';
import { FaRegImage } from 'react-icons/fa6';
import { IoDocumentTextSharp } from 'react-icons/io5';
import { BsChevronDown } from 'react-icons/bs';

export const FrodWorkContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const FrodWorkMainText = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
`;

export const FrodWorkText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: ${props => (props.switcher ? '#ffffff' : '#000000')};
  margin: 0;
`;

export const FrodWorkSubText = styled.div`
  margin-bottom: 35px;
`;

export const FrodTLWorkSubText = styled.div`
  display: flex;
`;

export const FrodWorkSubPar = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: #b1b1b1;
  margin: 0px;
`;
export const FrodWorkSubParSpan = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 16px;
  text-align: center;
  color: rgb(30, 175, 105);
  margin: 0px;
`;

export const FrodWorkComment = styled.div`
  margin-top: 10px;
  width: 873px;
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  padding: 10px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #717171;

  white-space: pre-wrap;
`;

export const FrodTextArea = styled.textarea`
  width: 539px;
  height: 179px;
  padding: 10px;
  background: #ffffff;
  border: 0.5px solid #d3d3d3;
  border-radius: 3px;
  resize: none;
  outline: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #717171;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const FrodButtonArea = styled.div`
  margin-top: 20px;
`;

export const GreenButton = styled.button`
  width: 100px;
  height: 27px;
  border: 1px solid #d3d3d3;
  filter: drop-shadow(0px 4px 3.7px rgba(0, 0, 0, 0.06));
  border-radius: 3px;
  background-color: transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1eaf69;
  margin-right: 10px;
  cursor: pointer;
`;

export const RedButton = styled.button`
  width: 100px;
  height: 27px;
  border: 1px solid #d3d3d3;
  filter: drop-shadow(0px 4px 3.7px rgba(0, 0, 0, 0.06));
  border-radius: 3px;
  background-color: transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #ff5454;
  margin-right: 10px;
  cursor: pointer;
`;

export const BrownButton = styled.button`
  width: 100px;
  height: 27px;
  border: 1px solid #d3d3d3;
  filter: drop-shadow(0px 4px 3.7px rgba(0, 0, 0, 0.06));
  border-radius: 3px;
  background-color: transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #af751e;
  margin-right: 10px;
  cursor: pointer;
`;

export const BlueButton = styled.button`
  width: 100px;
  height: 27px;
  border: 1px solid #d3d3d3;
  filter: drop-shadow(0px 4px 3.7px rgba(0, 0, 0, 0.06));
  border-radius: 3px;
  background-color: transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #1e8caf;
  margin-right: 10px;
  cursor: pointer;
`;

export const PurpleButton = styled.button`
  width: 100px;
  height: 27px;
  border: 1px solid #d3d3d3;
  filter: drop-shadow(0px 4px 3.7px rgba(0, 0, 0, 0.06));
  border-radius: 3px;
  background-color: transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${props => (props.switcher ? '#ffffff' : 'purple')};
  cursor: pointer;
`;
export const GetNewButton = styled.button`
  width: 100px;
  height: 27px;
  border: 1px solid #d3d3d3;
  filter: drop-shadow(0px 4px 3.7px rgba(0, 0, 0, 0.06));
  border-radius: 3px;
  background-color: transparent;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${props =>
    props.disabled ? 'red' : props.switcher ? '#ffffff' : 'purple'};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const FrodSwitcher = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const FrodSwitherText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #b1b1b1;
  margin-right: 10px;
`;

export const TextAreaWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileInp = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
`;

export const FileInpIconWrapper = styled.span`
  height: 35px;
  width: 60px;
  margin-right: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #b1b1b1;
`;

export const FileInpButtonText = styled.span`
  line-height: 1;
  margin-top: 1px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #b1b1b1;
`;

export const FileInpButton = styled.label`
  width: 100%;
  max-width: ${props => (props.selected ? '450px' : '220px')};
  height: 35px;
  background: #fff;
  border: 1px solid #b1b1b1;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
`;

export const FileInpIcon = styled(LuDownload)`
  width: 25px;
  height: 25px;
  color: #b1b1b1;
`;

export const ImgWrap = styled.div`
  position: relative;
  width: 560px;
  height: 300px;
  border: 2px solid #b1b1b1;
  margin-bottom: 15px;
`;

export const CloseBtnIcon = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  color: red;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const FileCont = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  width: 50px;
`;

export const ImgImg = styled(FaRegImage)`
  width: 20px;
  height: auto;
  color: #717171;
`;

export const DocImg = styled(IoDocumentTextSharp)`
  width: 20px;
  height: auto;
  color: #717171;
`;

export const HoldContainer = styled.div`
  margin-top: 100px;
  width: 895px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const HoldButton = styled.div`
  display: flex;
  width: ${({ expanded }) => (expanded ? '100%' : '210px')};
  height: ${({ expanded }) => (expanded ? '0' : '25px')};
  overflow: hidden;
  background: ${({ expanded }) => (expanded ? 'transparent' : '#d9d9d9')};
  border-radius: ${({ expanded }) => (expanded ? '0' : '5px')};
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  align-items: center;
  justify-content: center;
  color: ${({ expanded }) => (expanded ? 'transparent' : '#717171')};
  transition: all 0.5s ease;
  cursor: pointer;
`;

export const DownArrow = styled(BsChevronDown)`
  width: 14px;
  height: 14px;
  margin-left: 7px;
  color: #717171;
`;

export const HoldTableContainer = styled.div`
  width: ${({ expanded }) => (expanded ? '100%' : '0')};
  overflow: hidden;
  transition: all 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;
