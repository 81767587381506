import React, { useState } from 'react';
import Union from '../../../images/Union.png';
import {
  ArrowButton,
  BackButton,
  HeaderTicketChatConteiner,
  HeaderTicketChatMainItem,
  HeaderTicketChatMainText,
  ImgConteiner,
  MainQaImg,
  NavLinkStyled,
} from './HeaderTicketChat.style';
import { useNavigate } from 'react-router-dom';

const HeaderTicketChat = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <HeaderTicketChatConteiner>
      <ImgConteiner>
        <NavLinkStyled to="/">
          <MainQaImg src={Union} />
        </NavLinkStyled>
      </ImgConteiner>
      <HeaderTicketChatMainItem>
        <BackButton>
          <ArrowButton onClick={handleGoBack} />
        </BackButton>
        <HeaderTicketChatMainText>
          Ticket from chat GHTIX
        </HeaderTicketChatMainText>
      </HeaderTicketChatMainItem>
    </HeaderTicketChatConteiner>
  );
};

export default HeaderTicketChat;
