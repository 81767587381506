import { createAsyncThunk } from '@reduxjs/toolkit';
import handleMessage, {
  getScheduleCreation,
  getSchedulePersonalTable,
  getScheduleWorker,
  getUsers,
  handleSocketMessage,
  postNeededData,
  postScheduleCreation,
  postScheduleWorker,
  putScheduleShift,
  socket,
} from '../../services/API';

export const getUsersThunk = createAsyncThunk(
  'user/schedule',
  async ({ token, groups, tid }, thunkAPI) => {
    try {
      const response = await getUsers(token, groups, tid);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getScheduleCreationThunk = createAsyncThunk(
  'creation/schedule',
  async (token, thunkAPI) => {
    try {
      const response = await getScheduleCreation(token);
      return response;
    } catch (e) {
      console.error('Error in getScheduleCreationThunk:', e.message);
      console.error('Full error:', e);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getScheduleLunchWorkerThunk = createAsyncThunk(
  'worker/scheduleLunch',
  async ({ token, action = 'lunch' }, thunkAPI) => {
    try {
      const response = await getScheduleWorker(token, action);
      return response;
    } catch (e) {
      console.error('Error in getScheduleWorkerThunk:', e.message);
      console.error('Full error:', e);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getScheduleTbreakWorkerThunk = createAsyncThunk(
  'worker/scheduleTbreak',
  async ({ token, action = 'tbreak' }, thunkAPI) => {
    try {
      const response = await getScheduleWorker(token, action);
      return response;
    } catch (e) {
      console.error('Error in getScheduleWorkerThunk:', e.message);
      console.error('Full error:', e);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const postScheduleCreationThunk = createAsyncThunk(
  'creation/postSchedule',
  async ({ token, scheduleData }, thunkAPI) => {
    try {
      const response = await postScheduleCreation(token, scheduleData);
      return response;
    } catch (e) {
      console.error('Error in postScheduleCreationThunk:', e.message);
      console.error('Full error:', e);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const putScheduleShiftThunk = createAsyncThunk(
  'shift/putSchedule',
  async ({ token, scheduleData }, thunkAPI) => {
    try {
      const response = await putScheduleShift(token, scheduleData);
      return response;
    } catch (e) {
      console.error('Error in putScheduleShiftThunk:', e.message);
      console.error('Full error:', e);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getSchedulePersonalTableThunk = createAsyncThunk(
  'user/personal-table',
  async ({token, tid}, thunkAPI) => {
    try {
      const response = await getSchedulePersonalTable(token, tid);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const postScheduleWorkerThunk = createAsyncThunk(
  'schedule/worker',
  async ({ token, scheduleData }, thunkAPI) => {
    try {
      const response = await postScheduleWorker(token, scheduleData);
      return response;
    } catch (e) {
      console.error('Error in putScheduleShiftThunk:', e.message);
      console.error('Full error:', e);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const postNeededDataThunk = createAsyncThunk(
  'schedule/needed',
  async ({ token, neededData }, thunkAPI) => {
    try {
      const response = await postNeededData(token, neededData);
      return response;
    } catch (e) {
      console.error('Error in postNeededDataThunk:', e.message);
      console.error('Full error:', e);
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
