import styled from 'styled-components';
import { LuDownload } from 'react-icons/lu';

export const BugReportModall = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalContent = styled.div`
  border-radius: 10px;
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  position: relative;
`;

export const CloseBtn = styled.span`
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
`;

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const MainText = styled.h3`
  color: #aaa;
`;

export const ModalFormLabel = styled.label`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  color: #b1b1b1;

  margin-bottom: 8px;
`;

export const ModalText = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #b1b1b1;
  margin: 0;
`;

export const ModalFormTextArea = styled.textarea`
  height: 40px;
  width: 594px;
  background: #ffffff;
  border: 0.5px solid #d3d3d3;
  border-radius: 3px;
  resize: none;
  outline: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #717171;
  margin-bottom: 5px;
`;

export const GrayRadioButton = styled.input`
  appearance: none;
  position: relative;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  margin-right: 5px;

  &:checked::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px; /* Розмір точки */
    height: 10px; /* Розмір точки */
    background-color: gray;
    border-radius: 50%;
  }
`;

export const RadioButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const RadioButtonLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #717171;
  margin-bottom: 5px;
`;

export const ModalFormButtonConteiner = styled.div`
  margin-bottom: 10px;
`;

export const ModalSubmitButton = styled.button`
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 14px;

  margin-right: 15px;


  &:hover {
    background-color: #0056b3;
  }
`;

export const WrapForInputFile = styled.div`
  margin-bottom: 10px;
`;

export const FileInp = styled.input`
  opacity: 0;
  visibility: hidden;
  position: absolute;
`;

export const FileInpIconWrapper = styled.span`
  height: 30px;
  width: 60px;
  margin-right: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-right: 1px solid #b1b1b1;
`;

export const FileInpButtonText = styled.span`
  line-height: 1;
  margin-top: 1px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: #b1b1b1;
`;

export const FileInpButton = styled.label`
  width: 100%;
  max-width: ${props => (props.selected ? '450px' : '220px')};
  height: 30px;
  background: #fff;
  border: 1px solid #b1b1b1;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 3px;
  cursor: pointer;
`;

export const FileInpIcon = styled(LuDownload)`
  width: 25px;
  height: 25px;
  color: #b1b1b1;
`;
