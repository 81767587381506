import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { IoIosArrowDropleft } from 'react-icons/io';
import { FaRegWindowClose } from 'react-icons/fa';

export const AgentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubContainer = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  gap: 50px;
`;

export const StatisticAgentConteiner = styled.div`
  padding-left: 159px;
  padding-top: 105px;
`;

export const MainStatisticsText = styled.h1`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: ${props => (props.switcher ? '#D8D8D8' : '#000')};
  margin-top: 0;
  margin-bottom: 25px;
`;

export const MainStatisticsSpan = styled.span`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;

  color: #1eaf69;
`;

export const StatisticsSubText = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: ${props => (props.switcher ? '#D8D8D8' : '#000')};
  margin-top: 0;
  margin-bottom: 5px;
`;

export const StatisticDate = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #b1b1b1;
  margin-top: 0;
  margin-bottom: 39px;
`;

export const LinkButton = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;

  margin-bottom: 20px;

  &:hover {
    color: #007bff;
  }
`;

export const LinkArrow = styled(IoIosArrowDropleft)`
  width: 30px;
  height: 30px;
`;

export const StatisticFilterConteiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  label {
    font-size: 16px;
    color: #333;
  }
  input,
  .react-datepicker-wrapper {
    font-size: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px;
  }
`;

export const FlexBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const UpdateStat = styled(FaRegWindowClose)`
  width: 30px;
  height: 30px;

  cursor: pointer;

  margin-left: 20px;

  &:hover {
    color: #ffd700;
  }
`;

export const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  & span:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: #2196f3;
  }

  input:checked + span:before {
    transform: translateX(26px);
  }
`;

export const ToggleLabel = styled.span`
  margin-left: 10px;
  font-size: 14px;
`;
