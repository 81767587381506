import {
  AnalyticsBlock,
  AnalyticsItem,
  AnalyticsList,
  AnalyticsMainText,
  CorrectItem,
  IdInformation,
  MainText,
  Minus,
  MistakeItem,
  PercentBarBlock,
  PercentItem,
  Plus,
  Progress,
  ProgressBar,
  ResultsBlock,
  SupportMenuConteiner,
  TextInformationBlock,
  User,
  UserBlock,
  UserGradeBlock,
  UserImg,
  UserJobTitle,
  UserName,
} from './SupportMenu.styled';
import imgTest from '../../../images/userPhoto.png';

const SupportMenu = () => {
  return (
    <SupportMenuConteiner>
      <TextInformationBlock>
        <MainText>CAT_AWOL_19022024_23_14.wav</MainText>
        <IdInformation>#2321565</IdInformation>
      </TextInformationBlock>
      <UserGradeBlock>
        <User>
          <UserImg src={imgTest}></UserImg>
          <UserBlock>
            <UserJobTitle>Agent Name</UserJobTitle>
            <UserName>Артём Ковальцев</UserName>
          </UserBlock>
        </User>
        <PercentBarBlock>
          <PercentItem>Call Score 80%</PercentItem>
          <ProgressBar>
            <Progress percent={80} />
          </ProgressBar>
        </PercentBarBlock>
        <ResultsBlock>
          <MistakeItem>Ошибок: 17</MistakeItem>
          <CorrectItem>Корректно: 4</CorrectItem>
        </ResultsBlock>
      </UserGradeBlock>
      <AnalyticsBlock>
        <AnalyticsMainText>Analytics</AnalyticsMainText>
        <AnalyticsList>
          <AnalyticsItem>Did the operator give his/her name? <Plus/></AnalyticsItem>
          <AnalyticsItem>Did the operator give his/her name? <Minus/></AnalyticsItem>
        </AnalyticsList>
      </AnalyticsBlock>
    </SupportMenuConteiner>
  );
};

export default SupportMenu;
