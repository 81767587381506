import styled from 'styled-components';
import { IoIosCloseCircleOutline } from 'react-icons/io';

export const Modal = styled.div`
  width: 300px;
  height: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border: 3px solid #ccc;
  border-radius: 25px;
`;

export const ModalConteiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalText = styled.p`
  margin-bottom: 4px;
  font-family: 'Inter';
  font-style: normal;
  line-height: 24px;
  color: #808080;
`;

export const ModalMainText = styled.h1`
  margin-bottom: 4px;
  font-family: 'Inter';
  font-style: normal;
  line-height: 24px;
  color: #808080;
`;

export const ModalButtonConteiner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const CloseButton = styled(IoIosCloseCircleOutline)`
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #808080;

  &:hover {
    color: #1eaf69;
  }
`;

export const ModalInput = styled.input`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 16px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #808080;
  border-radius: 10px;
  color: #808080;
`;

export const ModalForm = styled.form`
  display: flex;
  flex-direction: column;
`;

export const ModalLabel = styled.label`
  font-size: 14px;
  margin-bottom: 4px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #808080;
`;

export const ModalButton = styled.button`
  margin-top: 10px;
  padding: 10px 30px;
  cursor: pointer;
  flex-shrink: 0;
  border-radius: 3px;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 4px 3.7px 0px rgba(0, 0, 0, 0.06);
  color: #1eaf69;
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background-color: transparent;

  &:hover {
    color: #333;
  }
`;

export const ModalSelect = styled.select`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 10px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #808080;
  color: #808080;
`;

export const ModalOption = styled.option`
  color: #808080;
  font-family: 'Inter';
  font-style: normal;
  line-height: 24px;
`;


