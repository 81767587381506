import styled from 'styled-components';
import { RxAvatar } from 'react-icons/rx';

export const DetailsTicketChatConteiner = styled.div`
  margin-left: 1562px;
  width: 680px;
  height: 100%;
  position: fixed;
`;

export const DetailsBlock = styled.div`
  width: 320px;
  height: 194px;

  margin: 15px;

  border: 1px solid #dfdaeb;
  border-radius: 5px;
`;

export const MainText = styled.h1`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;

  margin: 15px;

  color: #0a1019;
`;

export const DetailsList = styled.ul`
  list-style: none;

  padding-left: 15px;
  margin: 0;
`;

export const DetailsItem = styled.li`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;

  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  color: #b7b0c7;
`;

export const DetailsItemOption = styled.li`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;

  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  color: #b7b0c7;
`;

export const DetailsItemId = styled.span`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;

  padding-left: 5px;

  color: #ae8cff;
`;

export const DetailsSpan = styled.span`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 16px;

  padding-left: 5px;

  color: #0a1019;
`;

export const DetailsSpanPending = styled.span`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ae8cff;

  margin-left: 5px;

  padding: 1px;
  border: 1px solid #ae8cff;
  border-radius: 4px;
`;

export const DetailsSpanUrgent = styled.span`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ff4f4f;

  margin-left: 5px;

  padding: 1px;
  border: 1px solid #ff4f4f;
  border-radius: 4px;
`;

export const DetailsButton = styled.button`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;

  background-color: transparent;

  border: 0;

  margin-left: 15px;
  margin-right: 15px;

  color: #1eaf69;

  cursor: pointer;
`;

export const DetailsButtonFollower = styled.button`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;

  background-color: transparent;

  border: 0;

  margin-left: 0;

  color: #1eaf69;

  cursor: pointer;
`;

export const ResponsibilityBlock = styled.div`
  width: 320px;
  height: 281px;

  margin: 15px;

  border: 1px solid #dfdaeb;
  border-radius: 5px;
`;

export const SelectDetails = styled.select`
  width: 240px;
  height: 25px;

  margin-top: 10px;
  margin-bottom: 10px;

  border: 1px solid #dfdaeb;
  border-radius: 5px;
`;

export const DetailsOption = styled.option`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  color: #0a1019;
`;

export const ColorBlock = styled.div`
  width: 13px;
  height: 13px;

  background: #dbd19b;
  border-radius: 3px;
`;

export const UserBlock = styled.div`
  width: 190px;
  display: flex;
  flex-wrap: wrap;
`;

export const DetailsFlexBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const DetailsUser = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;

  height: 13px;

  color: #0a1019;

  border: 1px solid #dfdaeb;
  border-radius: 5px;

  padding: 6px;

  margin: 5px;
`;

export const RequesterBlock = styled.div`
  width: 320px;
  height: 101px;

  margin: 15px;

  border: 1px solid #dfdaeb;
  border-radius: 5px;
`;

export const MainRequesterBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UserNameBlock = styled.div``;

export const UserInfo = styled.div`
  display: flex;
  margin-left: 15px;
`;

export const UserName = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;

  margin: 0;

  color: #0a1019;
`;

export const UserEmail = styled.p`
  font-family: 'Geologica';
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;

  margin: 0;

  color: #8a8a8a;
`;

export const Avatar = styled(RxAvatar)`
  width: 30px;
  height: 30px;

  margin-right: 10px;
`;
