import DataBase from 'components/DataBas/DataBase';
import { DataBasePageConteiner, Container } from './DataBasePage.styled';
import SideBarDataBase from 'components/DataBas/SideBarDB/SideBarDataBase';
import HeaderDatBas from 'components/DataBas/HeaderDB/HeaderDatBas';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const DataBasePage = () => {
  const [articleId, setArticleId] = useState(null);

  const handleValueChange = newValue => {
    setArticleId(newValue);
  };

  return (
    <DataBasePageConteiner>
      <HeaderDatBas />
      <Container>
        <SideBarDataBase onValueChange={handleValueChange} />
        <DataBase articleId={articleId} />
      </Container>
    </DataBasePageConteiner>
  );
};
export default DataBasePage;
