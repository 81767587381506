import HeaderDatBas from "components/DataBas/HeaderDB/HeaderDatBas";
import SpacesDB from "components/DataBas/SpacesDB/SpacesDB";

const { DataBasePageConteiner } = require("../DataBasePage.styled")




const SpacesDBPage = () => {
    return (
       <DataBasePageConteiner>
        <HeaderDatBas/>
        <SpacesDB/>
       </DataBasePageConteiner>
    );
    };
    export default SpacesDBPage;