import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { IoIosArrowDropleft } from 'react-icons/io';
import { FaRegWindowClose } from 'react-icons/fa';

export const SearchAgent = styled.input`
  box-sizing: border-box;

  width: 1173px;
  height: 30px;
  padding-left: 30px;
  background: #ffffff;
  border: 1px solid #dfdaeb;
  border-radius: 5px;
  outline: none;
  font-family: 'Geologica', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
`;

export const StatisticDateSuper = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #b1b1b1;
  margin-top: 0;
  margin-bottom: 25px;
`;

export const SearchContainer = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
`;

export const SearchImg = styled.img`
  position: absolute;
  left: 10px;
  top: 10px;
`;

export const ParamConatiner = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

export const Param = styled.div`
  width: 207px;
  height: 24px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: ${props => (props.switcher ? '#D8D8D8' : '#000')};
  text-align: center;
  justify-content: center;
  margin-right: 44px;
  &:first-child {
    margin-left: 210px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const AgentsContainer = styled.div``;

export const OneAgent = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const NamePlace = styled.div`
  width: 200px;
  height: 30px;
  background: #ae8cff;
  border-radius: 15px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  cursor: pointer;

  color: #ffffff;
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -10px;
`;

export const StatisticsSuperConteiner = styled.div`
  padding-left: 159px;
  padding-top: 105px;
`;

export const InfoPlace = styled.div`
  border: 1px solid #d3d3d3;

  width: 243px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: ${props => (props.switcher ? '#D8D8D8' : '#000')};
  &:first-child {
    width: 253px;
    border-left: none;
  }
  &:last-child {
    border-radius: 0 15px 15px 0;
  }
`;

export const NavLinkSup = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

export const LinkButtonSup = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;

  margin-bottom: 20px;

  &:hover {
    color: #007bff;
  }
`;

export const LinkArrow = styled(IoIosArrowDropleft)`
  width: 30px;
  height: 30px;
`;

export const StatisticFilterConteiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
  label {
    font-size: 16px;
    color: #333;
  }
  input,
  .react-datepicker-wrapper {
    font-size: 16px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 150px;
  }
`;

export const FlexBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const UpdateStat = styled(FaRegWindowClose)`
  width: 30px;
  height: 30px;

  cursor: pointer;

  margin-left: 20px;

  &:hover {
    color: #ffd700;
  }
`;

export const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  & span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  & span:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + span {
    background-color: #2196f3;
  }

  input:checked + span:before {
    transform: translateX(26px);
  }
`;

export const ToggleLabel = styled.span`
  margin-left: 10px;
  font-size: 14px;
`;
