import HeaderDatBas from "components/DataBas/HeaderDB/HeaderDatBas";
import HomeDB from "components/DataBas/HomeDB/HomeDB";

const { DataBasePageConteiner } = require("../DataBasePage.styled")




const HomeDBPage = () => {
    return (
       <DataBasePageConteiner>
        <HeaderDatBas/>
        <HomeDB/>
       </DataBasePageConteiner>
    );
    };
    export default HomeDBPage;