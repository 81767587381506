import { useState } from "react";
import { BugIcon, BugReportButton } from "./BugReport.styled";
import BugReportModal from "./BugReportModal/BugReportModal";
import { selectSwitcher } from "../../redux/selectors";
import { useSelector } from "react-redux";

const BugReport = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const switcher = useSelector(selectSwitcher);

  const openModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'visible';
  };

  return (
    <div>
      <BugReportButton onClick={openModal}>
        <BugIcon switcher={switcher}/>
      </BugReportButton>
      {isModalOpen ? <BugReportModal onClose={closeModal} /> : <></>}
    </div>
  );
};

export default BugReport;
