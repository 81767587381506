import { createSlice } from '@reduxjs/toolkit';
import { getAntifraudHoldRequests, getAntifraudRequestEscaladeThunk, getFraudTeamStatisticsThunk,getAntifraudRequestThunk, getAntifraudShiftThunk,getFraudPersonalStatisticsThunk,getFraudTlStatisticsThunk,postAntifraudRequestThunk, postAntifraudShiftThunk, getFraudPersonalStatisticsPkThunk } from './fraudActions';

const initialState = {
  pk: null,
  decision: null,
  comment: null,
  type: "requests",
  auto_get_next: false,
  files:[],
  projects: null,
  loading: null,
  error: null,
  project: null,
  shift_type: null,
  request_type: null,
  request: null,
  holds: null,
  stat: null,
  agentStat: null,
  statTl: null,
  statSup: null,
};



const fraudSlice = createSlice({
  name: 'fraud',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getAntifraudShiftThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getAntifraudShiftThunk.fulfilled, (state, action) => {
        state.projects = action.payload;
        state.loading = false;
      })
      .addCase(getAntifraudShiftThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getFraudPersonalStatisticsThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getFraudPersonalStatisticsThunk.fulfilled, (state, action) => {
        state.stat = action.payload;
        state.loading = false;
      })
      .addCase(getFraudPersonalStatisticsThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getFraudTeamStatisticsThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getFraudTeamStatisticsThunk.fulfilled, (state, action) => {
        state.statSup = action.payload;
        state.loading = false;
      })
      .addCase(getFraudTeamStatisticsThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(postAntifraudShiftThunk.pending, state => {
        state.loading = true;
      })
      .addCase(postAntifraudShiftThunk.fulfilled, (state, action) => {
        state.project = action.payload.project;
        state.shift_type = action.payload.shift_type;
        state.request_type = action.payload.request_type;
        state.loading = false;
      })
      .addCase(postAntifraudShiftThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })


      .addCase(getAntifraudRequestThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getAntifraudRequestThunk.fulfilled, (state, action) => {
        state.request = action.payload;
        state.loading = false;
      })
      .addCase(getAntifraudRequestThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(postAntifraudRequestThunk.pending, state => {
        state.loading = true;
      })
      .addCase(postAntifraudRequestThunk.fulfilled, (state, action) => {
        state.pk = action.payload.pk;
        // state.data.decision = action.payload.data.decision;
        // state.data.comment = action.payload.data.comment;
        // state.data.auto_get_next = action.payload.data.auto_get_next;
        state.loading = false;
      })
      .addCase(postAntifraudRequestThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })
      .addCase(getAntifraudRequestEscaladeThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getAntifraudRequestEscaladeThunk.fulfilled, (state, action) => {
        state.request = action.payload;
        state.loading = false;
      })
      .addCase(getAntifraudRequestEscaladeThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getAntifraudHoldRequests.pending, state => {
        state.loading = true;
      })
      .addCase(getAntifraudHoldRequests.fulfilled, (state, action) => {
        state.holds = action.payload;
        state.loading = false;
      })
      .addCase(getAntifraudHoldRequests.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getFraudTlStatisticsThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getFraudTlStatisticsThunk.fulfilled, (state, action) => {
        state.statTl = action.payload;
        state.loading = false;
      })
      .addCase(getFraudTlStatisticsThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

      .addCase(getFraudPersonalStatisticsPkThunk.pending, state => {
        state.loading = true;
      })
      .addCase(getFraudPersonalStatisticsPkThunk.fulfilled, (state, action) => {
        state.agentStat = action.payload;
        state.loading = false;
      })
      .addCase(getFraudPersonalStatisticsPkThunk.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      })

  },
});

export default fraudSlice.reducer;