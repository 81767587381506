import styled from 'styled-components';

export const InfoDiv = styled.div`
  width: 589px;
  height: 250px;
  border: 1px solid #d3d3d3;
  border-radius: 15px;
`;

export const InfoHeader = styled.p`
  background: #ae8cff;
  height: 40px;

  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #ffffff;
  margin: 0;
  border-radius: 15px 15px 0px 0px;
`;

export const CradContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 13px 10px 10px 10px;
`;
export const InfoCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 179px;
  height: 187px;
  background: transparent;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.11);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
`;

export const CardNumText = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  display: flex;
  align-items: center;

  color: ${props => (props.switcher ? '#D8D8D8' : '#0a1019')};
  margin: 0;
  margin-bottom: 30px;
`;

export const CardNumGreenText = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 73px;
  display: flex;
  align-items: center;
  color: #1eaf69;
  margin: 0;
  margin-bottom: 30px;
`;

export const SmallText = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #b1b1b1;
`;
