import HeaderTicketChat from 'components/TicketChatPage/HeaderTicketChat/HeaderTicketChat';
import {
  MainTicketChatConteiner,
  TicketChatConteiner,
} from './TicketChatPage.styled';
import QaSideBar from 'components/QaPage/QaSideBar/QaSideBar';
import MainTicketChat from 'components/TicketChatPage/MainTicketChat/MainTicketChat';
import DetailsTicketChat from 'components/TicketChatPage/DetailsTicketChat/DetailsTicketChat';

const TicketChat = () => {
  return (
    <TicketChatConteiner>
      <HeaderTicketChat />
      <MainTicketChatConteiner>
        <QaSideBar />
        <MainTicketChat />
        <DetailsTicketChat />
      </MainTicketChatConteiner>
    </TicketChatConteiner>
  );
};

export default TicketChat;
