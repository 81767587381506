import {
  QaGDeskConteiner,
  QaGDeskSearch,
  QaGDeskSearchWrap,
  QaGDeskIcon,
  QaGDeskTicketsText,
  QaGDeskTicketCon,
  QaGDeskTicketName,
  QaGDeskTicketNum,
} from './QaGDesk.styled';
import GIcon from '../../../images/G.png';

const QaGDesk = () => {
  return (
    <QaGDeskConteiner>
      <QaGDeskSearchWrap>
        <QaGDeskIcon src={GIcon} />
        <QaGDeskSearch placeholder=" Search by tickets" />
      </QaGDeskSearchWrap>
      <QaGDeskTicketsText>All Tickets</QaGDeskTicketsText>
      <QaGDeskTicketCon>
        <QaGDeskTicketName>Tickets to handle</QaGDeskTicketName>
        <QaGDeskTicketNum>99</QaGDeskTicketNum>
      </QaGDeskTicketCon>
      <QaGDeskTicketCon>
        <QaGDeskTicketName>My open tickets</QaGDeskTicketName>
        <QaGDeskTicketNum>14</QaGDeskTicketNum>
      </QaGDeskTicketCon>
      <QaGDeskTicketsText>Shared view</QaGDeskTicketsText>
      <QaGDeskTicketCon>
        <QaGDeskTicketName>Group Name</QaGDeskTicketName>
        <QaGDeskTicketNum>0</QaGDeskTicketNum>
      </QaGDeskTicketCon>
    </QaGDeskConteiner>
  );
};

export default QaGDesk;
