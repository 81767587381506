import styled from 'styled-components';
import DatBasePhoto from '../../../images/HomeDbPic.png';

import { NavLink } from 'react-router-dom';

export const StyledNav = styled(NavLink)`
  text-decoration: none;
`;

export const ProjList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  flex-direction: column;
`;

export const ProjItem = styled.li`
  margin-top: 50px;
  position: relative;
  display: inline-block;
  text-align: center;
  width: 800px;
  height: 123px;
  background-image: url(${DatBasePhoto});
  background-size: cover;
  background-position: center;
  transition: transform 0.3s; /* Добавляем плавное переходное свойство */

  &:hover {
    transform: scale(1.1); /* Увеличиваем размер при наведении */
  }
`;

export const ProjName = styled.h3`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  margin: 0;
  color: #ffffff;
`;

export const LiPic = styled.img`
  height: 123px;
  width: 800px;
`;
