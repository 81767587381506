import styled from 'styled-components';

export const FrodTableContainer = styled.table`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #717171;
  border-collapse: collapse;
  width: 895px;
  margin: 0 auto;
  border-radius: 3px;
  overflow: hidden; /* Для обрезки бордера внутри border-radius */
  margin-top: 10px;

  &:first-child{
    margin-top: 0;
  }
`;

export const FrodTableHeader = styled.th`
  border: 1px solid #dddddd;
  text-align: center; /* Центрирование текста */
  padding: 5px;
  width: 127.8px;
  background: #d9d9d9;
`;

export const FrodTableCell = styled.td`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${props => (props.switcher ? '#ffffff' : '#000')};

  width: 165px;
  height: 25px;
  border: 1px solid #dddddd;
  text-align: center; 
  padding: 8px;

  &:nth-child(2) {
    background-color: #aff1ff;
    color: #1e8caf;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }

  &:nth-last-child(1) {
    color: #1eaf69;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;
