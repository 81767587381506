import styled from 'styled-components';
import { IoBugOutline } from 'react-icons/io5';

export const BugReportButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #d6d6d6;
  cursor: pointer;
  color: #000;
  &:hover {
    background-color: #d6d6d6;
  }
`;

export const BugIcon = styled(IoBugOutline)`
  width: 20px;
  height: 20px;
  
  color: ${props => (props.switcher ? '#fff' : '#000')};
`;
