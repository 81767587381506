import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';

export const StyledNav = styled(NavLink)`
  text-decoration: none;
`;

export const Container = styled.div`
  padding-top: 30px;
  padding-left: 50px;
`;

export const TextRouteDataBase = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #7b7b7b;
  margin: 0;
`;

export const ProjList = styled.ul`
  padding: 0;
  display: flex;
  list-style: none;
  margin-top: 50px;
  flex-wrap: wrap;
  gap: 30px;
`;

export const ProjItem = styled.li`
  position: relative;
  border: 1px solid ${({ borderColor }) => borderColor};
  border-radius: 7px 7px 4px 4px;
  width: 170px;
  height: 200px;
  transition: transform 0.3s; /* Добавляем плавное переходное свойство */

  &:hover {
    transform: scale(1.1); /* Увеличиваем размер при наведении */
  }

  &:hover div {
    visibility: visible; /* При наведении на ProjItem отображаем крестик */
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  background-color: white;
  top: -10px;
  right: -10px;
  color: red;
  width: 20px;
  height: 20px;
  border: 2px solid red;
  border-radius: 50px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  visibility: hidden; /* По умолчанию скрываем крестик */
`;

export const RedCross = styled(RxCross2)`
  width: 20px;
  height: 20px;
  color: red;
`;

export const ProjName = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  // color: #242424;
  margin: 0;
  padding: 9px 0px 0px 11px;
  color: ${props => (props.switcher ? '#D8D8D8' : '#242424')};
`;

export const CreateButton = styled.button`
  margin-left: 30px;
  background-color: #1eaf69;
  padding: 5px 10px;
  color: #fff;
  border: none;
  border-radius: 5px;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalMainText = styled.h1`
  margin-bottom: 10px;
  margin-top: 0;
`;
export const ProjLabel = styled.p`
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
  color: #b1b1b1;

  margin-bottom: 8px;
`;
export const ProjInput = styled.input`
  outline: none;
  border: 1px solid #b1b1b1;
  width: 300px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 17px;
`;

export const ProjPreImg = styled.div`
  margin-top: 10px;
`;

export const CreateButtonModal = styled.button`
  background-color: #1eaf69;
  padding: 5px 10px;
  color: #fff;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
`;

export const ModalHeadConatiner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Cross = styled(RxCross2)`
  width: 30px;
  height: 30px;
`;

export const ProjImg = styled.img`
  width: 170px;
  height: 160px;
`;
