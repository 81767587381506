import FrodSelectPage from 'components/Frod/FrodSelectPage/FrodSelectPage';
import { FrodPageContainer } from './FrodPage.styled';
import { useEffect, useState } from 'react';
import FrodWorkPart from 'components/Frod/FrodWorkPart/FrodWorkPart';
import FrodDocuments from 'components/Frod/FrodDocuments/FrodDocuments';
import { useSelector } from 'react-redux';
import { selectUserConfig } from '../../redux/selectors';


const FrodPage = ({isRequest, selectedValues, updateSelectedValues}) => {
  // const [selectedProject, setSelectedProject] = useState(null);
  // const [selectedShift, setSelectedShift] = useState(null);

  const config = useSelector(selectUserConfig);

  const project = config?.project;
  const shiftType = config?.shift_type;

  return project !== null && shiftType !== null ? (
    <>
      <FrodPageContainer>
          <>
            {shiftType === 'requests' ? (
              <FrodWorkPart project={project} isUpdRequest={isRequest}/>
            ) : shiftType === 'affiliate_requests' ? (
              <FrodDocuments project={project} shift={shiftType} />
            ) : shiftType === 'documents' ? (
              <FrodDocuments project={project} shift={shiftType} />
            ) : shiftType === 'support' ? (
              <FrodDocuments project={project} shift={shiftType} />
            ) : null}
          </>
      </FrodPageContainer>
    </>
  ) : (
    <FrodPageContainer>
      {selectedValues.selectedProject && selectedValues.selectedShift ? (
        <>
          {selectedValues.selectedShift === 'Requests' ? (
            <FrodWorkPart project={selectedValues.selectedProject} isUpdRequest={isRequest}/>
          ) : selectedValues.selectedShift === 'Affiliate Requests' ? (
            <FrodDocuments project={selectedValues.selectedProject} shift={selectedValues.selectedShift} />
          ) : selectedValues.selectedShift === 'Documents' ? (
            <FrodDocuments project={selectedValues.selectedProject} shift={selectedValues.selectedShift} />
          ) : selectedValues.selectedShift === 'Supports' ? (
            <FrodDocuments project={selectedValues.selectedProject} shift={selectedValues.selectedShift} />
          ) : null}
        </>
      ) : (
        <FrodSelectPage />
      )}
    </FrodPageContainer>
  );
};

export default FrodPage;
