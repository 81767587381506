import { useSelector } from 'react-redux';
import { getUser, isAuthenticated,selectIsRefreshing } from '../redux/selectors';


export const useAuth = () => {
  return {
    user: useSelector(getUser),
    isAuth: useSelector(isAuthenticated),
    isRefreshing: useSelector(selectIsRefreshing),
  };
};


// import {
//   selectUser,
//   selectIsLoggedIn,
//   selectIsRefreshing,
// } from 'redux/auth/selectors';

// export const useAuth = () => {
//   const isLoggedIn = useSelector(selectIsLoggedIn);
//   const isRefreshing = useSelector(selectIsRefreshing);
//   const user = useSelector(selectUser);

//   return {
//     isLoggedIn,
//     isRefreshing,
//     user,
//   };
// };
