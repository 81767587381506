import {
  TextRouteDataBase,
  Container,
  ProjList,
  ProjName,
  ProjItem,
  StyledNav,
  CreateButton,
  TitleContainer,
  ModalMainText,
  ProjLabel,
  ProjInput,
  ProjPreImg,
  CreateButtonModal,
  ModalHeadConatiner,
  Cross,
  ProjImg,
  CloseButton,
  RedCross,
} from './ProjectPage.styled';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectAccessToken,
  selectNodeDataBase,
  selectSwitcher,
  selectUserDepartment,
  selectUserType,
} from '../../../redux/selectors';
import {
  deleteDataBaseNodeThunk,
  getDataBaseNodeThunk,
  postDataBaseNodeThunk,
} from '../../../redux/database/databaseActions';
import CreateProjModal from './CreateProjModal/CreateProjModal';
import { Notify } from 'notiflix';
import { Load } from 'components/App.styled';
import { MutatingDots } from 'react-loader-spinner';

const ProjectPage = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const token = useSelector(selectAccessToken);
  const uNode = useSelector(selectNodeDataBase);
  const utype = useSelector(selectUserType);
  const depart = useSelector(selectUserDepartment);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [hexColor, setHexColor] = useState('');
  const [projNode, setProjNode] = useState();
  const [selectedImage, setSelectedImage] = useState(null);
  const [files, setFiles] = useState(null);

  const switcher = useSelector(selectSwitcher);
  const loading = useSelector(state => state.database.loading);

  useEffect(() => {
    dispatch(getDataBaseNodeThunk({ token, id: id }));
  }, []);

  useEffect(() => {
    setProjNode(uNode);
  }, [uNode]);

  const handleCreateButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleImageChange = e => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleCreateProject = async () => {
    const shiftData = new FormData();
    shiftData.append('parent_node', id);
    shiftData.append('title', projectName);
    shiftData.append('subtitle', hexColor);
    shiftData.append('image', files);

    const response = await dispatch(
      postDataBaseNodeThunk({ token, neededData: shiftData })
    );

    if (response.meta.requestStatus === 'fulfilled') {
      Notify.success('Created project ' + projectName);
      handleCreateButtonClick();
      setProjectName('');
      setHexColor('');
      setFiles(null);
      setSelectedImage(null);
      dispatch(getDataBaseNodeThunk({ token, id: id }));
      handleCloseModal();
    } else {
      Notify.failure('Ooops! Something went wrong');
      handleCreateButtonClick();
      setProjectName('');
      setHexColor('');
      setFiles(null);
      setSelectedImage(null);
    }
  };

  const handleDelete = async delId => {
    await dispatch(deleteDataBaseNodeThunk({ token, id: delId }));
    await dispatch(getDataBaseNodeThunk({ token, id: id }));
  };

  return (
    <>
      {loading ? (
        <Load>
          <MutatingDots
            height="100"
            width="100"
            color="#AFFFB7"
            secondaryColor="#AFFFB7"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </Load>
      ) : (
        <Container>
          <TitleContainer>
            <TextRouteDataBase>
              {depart === 'Supports' ? 'Customer Support' : <></>}
              {depart === 'Anti-Fraud' ? 'Anti-Fraud' : <></>}
            </TextRouteDataBase>
            {utype === 'admin' ? (
              <CreateButton
                onClick={() => {
                  handleCreateButtonClick();
                  setProjectName('');
                  setHexColor('');
                  setFiles(null);
                  setSelectedImage(null);
                }}
              >
                Create Project
              </CreateButton>
            ) : (
              <></>
            )}

            <CreateProjModal isOpen={isModalOpen} onClose={handleCloseModal}>
              <ModalHeadConatiner>
                <ModalMainText>Создать проект</ModalMainText>
                <Cross onClick={() => handleCloseModal()} />
              </ModalHeadConatiner>
              <ProjLabel>Имя проекта</ProjLabel>
              <ProjInput
                type="text"
                value={projectName}
                onChange={e => setProjectName(e.target.value)}
              />
              <ProjLabel>Цвет HEX(в формате #ffffff)</ProjLabel>
              <ProjInput
                type="text"
                value={hexColor}
                onChange={e => {
                  setHexColor(e.target.value);
                }}
              />
              <ProjLabel>Logo проекта</ProjLabel>
              <ProjInput
                type="file"
                onChange={e => {
                  setFiles(e.target.files[0]);
                  handleImageChange(e);
                }}
                accept=".jpg, .jpeg, .png"
              />
              <ProjPreImg>
                {selectedImage && (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                  />
                )}
              </ProjPreImg>
              <CreateButtonModal onClick={handleCreateProject}>
                Создать проект
              </CreateButtonModal>
            </CreateProjModal>
          </TitleContainer>
          <ProjList>
            {projNode?.nodes?.map(node => {
              return (
                <ProjItem borderColor={node.subtitle}>
                  {utype === 'admin' ? (
                    <CloseButton onClick={() => handleDelete(node.id)}>
                      <RedCross />
                    </CloseButton>
                  ) : (
                    <></>
                  )}
                  <StyledNav to={`/database/${node.id}`}>
                    <ProjImg
                      src={
                        `http${
                          process.env.REACT_APP_SECURE === 'true' ? 's' : ''
                        }://${process.env.REACT_APP_BACKEND_URL}` + node.image
                      }
                      alt="CatImg"
                    />
                    <ProjName switcher={switcher}>{node.title}</ProjName>
                  </StyledNav>
                </ProjItem>
              );
            })}
          </ProjList>
        </Container>
      )}
    </>
  );
};

export default ProjectPage;
