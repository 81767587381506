import {
    MainTextTicketChat,
  MainTicketChatConteiner,
  Shield,
  SubmitButton,
  SwitchContainer,
  SwitchInput,
  SwitchLabel,
  SwitchSlider,
  SwitcherBlock,
  TagBlock,
  TagButton,
  TextAreaQa,
  TextAreaToolsConteiner,
  TextBlock,
} from './MainTicketChat.styled';

const MainTicketChat = () => {
  return (
    <MainTicketChatConteiner>
      <TextBlock>
        <MainTextTicketChat>Steven</MainTextTicketChat>

      </TextBlock>
      <TextAreaQa />

      <SwitcherBlock>
        <SwitchContainer>
          <SwitchLabel>
            <SwitchInput type="checkbox" />
            <SwitchSlider />
          </SwitchLabel>
        </SwitchContainer>
        Private
      </SwitcherBlock>
      <TextAreaToolsConteiner>
        <TagBlock>
          <TagButton>
            <Shield />
            Add tag
          </TagButton>
        </TagBlock>
        <SubmitButton>Submit</SubmitButton>
      </TextAreaToolsConteiner>
    </MainTicketChatConteiner>
  );
};

export default MainTicketChat;
