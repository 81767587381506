import { CloseButton, ModalBackground, ModalContent } from "./CreateProjModal.styled";

const CreateProjModal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  const handleModalClick = e => {
    e.stopPropagation(); // Предотвращаем закрытие модального окна при клике внутри него
  };

  const handleCloseButtonClick = () => {
    onClose();
  };

  return (
    <ModalBackground onClick={onClose}>
      <ModalContent onClick={handleModalClick}>
        {children}
      </ModalContent>
    </ModalBackground>
  );
};

export default CreateProjModal;
