import {
  Avatar,
  ClosedBlock,
  GeneralInfoBlock,
  GeneralInfoConteiner,
  Geolocation,
  GeolocationItem,
  LinkRequesterTickets,
  MainDataBlock,
  MainDataItem,
  MainDataList,
  MainDataSpan,
  MainDataText,
  MainText,
  PreChatFormBlock,
  PreChatFormItem,
  PreChatFormList,
  PreChatFormSpan,
  PreChatFormText,
  RequesterTicketsBlock,
  RequesterTicketsItem,
  RequesterTicketsList,
  RequesterTicketsText,
  SecondMainText,
  TechnicalDataBlock,
  TechnicalDataItem,
  TechnicalDataList,
  TechnicalDataSpan,
  TechnicalDataText,
  Time,
  TimeGeolocationBlock,
  TimeItem,
  UserBlock,
  UserEmail,
  UserImg,
  UserInfo,
  UserName,
  UserNameBlock,
} from './GeneralInfo.styled';

const GeneralInfo = () => {
  return (
    <GeneralInfoConteiner>
      <GeneralInfoBlock>
        <MainText>General info</MainText>
        <UserBlock>
          <UserInfo>
            {/* <UserImg /> */}
            <Avatar />
            <UserNameBlock>
              <UserName>Артём Ковальцев</UserName>
              <UserEmail>artemkov982@gmail.com</UserEmail>
            </UserNameBlock>
          </UserInfo>
        </UserBlock>
        <TimeGeolocationBlock>
          <GeolocationItem>
            {' '}
            <Geolocation />
            Kazan, Tatarstan People’s Republic
          </GeolocationItem>
          <TimeItem>
            <Time />
            21:43 user local time
          </TimeItem>
        </TimeGeolocationBlock>
      </GeneralInfoBlock>
      <MainDataBlock>
        <MainText>Main data</MainText>
        <MainDataList>
          <MainDataItem>
            <MainDataText>
              <MainDataSpan>Device:</MainDataSpan> Mobile
            </MainDataText>
          </MainDataItem>
          <MainDataItem>
            <MainDataText>
              <MainDataSpan>Language:</MainDataSpan> Ru
            </MainDataText>
          </MainDataItem>
          <MainDataItem>
            <MainDataText>
              <MainDataSpan>Loyalty Level:</MainDataSpan> 4-Pro
            </MainDataText>
          </MainDataItem>
          <MainDataItem>
            <MainDataText>
              <MainDataSpan>Name:</MainDataSpan> Артём
            </MainDataText>
          </MainDataItem>
          <MainDataItem>
            <MainDataText>
              <MainDataSpan>Surname:</MainDataSpan> Ковальцев
            </MainDataText>
          </MainDataItem>
          <MainDataItem>
            <MainDataText>
              <MainDataSpan>e-mail:</MainDataSpan> artemkov982@gmail.com
            </MainDataText>
          </MainDataItem>
          <MainDataItem>
            <MainDataText>
              <MainDataSpan>Project:</MainDataSpan> Gama
            </MainDataText>
          </MainDataItem>
          <MainDataItem>
            <MainDataText>
              <MainDataSpan>Site:</MainDataSpan> https://gamacasino666.com
            </MainDataText>
          </MainDataItem>
          <MainDataItem>
            <MainDataText>
              <MainDataSpan>UserID:</MainDataSpan>{' '}
              https://www2.fundist.org/ru/Users/Summary/589385128
            </MainDataText>
          </MainDataItem>
          <MainDataItem>
            <MainDataText>
              <MainDataSpan>Tag:</MainDataSpan> PriVip
            </MainDataText>
          </MainDataItem>
        </MainDataList>
      </MainDataBlock>
      <PreChatFormBlock>
        <MainText>Pre-chat form</MainText>
        <PreChatFormList>
          <PreChatFormItem>
            <PreChatFormText>
              <PreChatFormSpan>Name:</PreChatFormSpan> Артём Ковальцев
            </PreChatFormText>
          </PreChatFormItem>
          <PreChatFormItem>
            <PreChatFormText>
              <PreChatFormSpan>e-mail:</PreChatFormSpan> artemkov982@gmail.com
            </PreChatFormText>
          </PreChatFormItem>
        </PreChatFormList>
      </PreChatFormBlock>
      <TechnicalDataBlock>
        <MainText>Technical data</MainText>
        <TechnicalDataList>
          <TechnicalDataItem>
            <TechnicalDataText>
              <TechnicalDataSpan>IP:</TechnicalDataSpan> 173.121.65.9
            </TechnicalDataText>
          </TechnicalDataItem>
          <TechnicalDataItem>
            <TechnicalDataText>
              <TechnicalDataSpan>OS/Device:</TechnicalDataSpan> iOS (17.2.1)
            </TechnicalDataText>
          </TechnicalDataItem>
          <TechnicalDataItem>
            <TechnicalDataText>
              <TechnicalDataSpan>Browser:</TechnicalDataSpan> Mobile Safari
              (17.2)
            </TechnicalDataText>
          </TechnicalDataItem>
        </TechnicalDataList>
      </TechnicalDataBlock>
      <RequesterTicketsBlock>
        <MainText>Requester tickets</MainText>
        <SecondMainText>Recent tickets</SecondMainText>
        <RequesterTicketsList>
          <RequesterTicketsItem>
            <ClosedBlock>CLOSED</ClosedBlock>
            <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
          </RequesterTicketsItem>
          <RequesterTicketsItem>
            <ClosedBlock>CLOSED</ClosedBlock>
            <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
          </RequesterTicketsItem>
          <RequesterTicketsItem>
            <ClosedBlock>CLOSED</ClosedBlock>
            <LinkRequesterTickets>Ticket 2314fgjeg2</LinkRequesterTickets>
          </RequesterTicketsItem>
        </RequesterTicketsList>
        <SecondMainText>Archived tickets</SecondMainText>
        <RequesterTicketsText>
          <LinkRequesterTickets>Search Archive</LinkRequesterTickets> to view
          this requester’s archived tickets.
        </RequesterTicketsText>
      </RequesterTicketsBlock>
    </GeneralInfoConteiner>
  );
};

export default GeneralInfo;
