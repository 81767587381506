import {
  Container,
  TextNameFile,
  ContainerMainInfo,
  UserImg,
  ContainerProfile,
  UserText,
  UserTitle,
  MainInfo,
  DatBasePic,
  ImgContainer,
  HeaderFileWrap,
  Pen,
  SettingPhotoWrap,
  EditTextArea,
  FileInputIcon,
  SettingWrap,
  FileInputContainer,
  SaveButton,
  CancelButton,
  PhotoLabel,
  CloseButton,
  EditTitleArea,
  RedCross,
} from './DataBase.styled';
import { useDispatch, useSelector } from 'react-redux';
import { Notify } from 'notiflix';
// import "react-image-gallery/styles/css/image-gallery.css";
// import ImageGallery from "react-image-gallery";
import {
  selectAccessToken,
  // selectArcticals,
  selectUserPhoto,
  selectArcticalCreatedAt,
  selectArcticalCreatedBy,
  selectArcticalText,
  selectArcticalTitle,
  selectArcticalImages,
  selectUserType,
  selectSwitcher,
} from '../../redux/selectors';
// import { useState } from 'react';

import userPhoto from '../../images/userPhoto.png';

import {
  getDatBaseArticleThunk,
  putDatBaseArticleThunk,
  postDatBaseArticlePhotoThunk,
  deleteDatBaseArticlePhotoThunk,
} from '../../redux/database/databaseActions';
import { useEffect, useState } from 'react';
// import { Load } from 'components/App.styled';
import { MutatingDots } from 'react-loader-spinner';

const DataBase = ({ articleId }) => {
  const dispatch = useDispatch();
  const uPhoto = useSelector(selectUserPhoto);
  const token = useSelector(selectAccessToken);
  // const uArcticals = useSelector(selectArcticals);
  const uCreatedBy = useSelector(selectArcticalCreatedBy);
  const uText = useSelector(selectArcticalText);
  const uCreatedAt = useSelector(selectArcticalCreatedAt);
  const uTitle = useSelector(selectArcticalTitle);
  const uImages = useSelector(selectArcticalImages);
  const utype = useSelector(selectUserType);

  const [showFileInput, setShowFileInput] = useState(false);
  const [editedText, setEditedText] = useState('');
  const [editedTitle, setEditedTitle] = useState('');
  const [files, setFiles] = useState('');

  const switcher = useSelector(selectSwitcher);
  const loading = useSelector(state => state.database.loading);

  // const images = uImages.map(image => ({
  //   original: 'http://test.catcasino-tools.com' + image.image,
  //   thumbnail: 'http://test.catcasino-tools.com' + image.image,
  //   description: image.description
  // }));

  const handleClick = ({ target }) => {
    if (!document.fullscreenElement) {
      target.requestFullscreen().catch(error => console.log(error));
    } else {
      document.exitFullscreen();
    }
  };

  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  }
  const formattedDate = formatDate(uCreatedAt);

  useEffect(() => {
    if (articleId !== null && articleId !== undefined) {
      dispatch(getDatBaseArticleThunk({ token, id: articleId }));
    }
  }, [articleId]);

  // useEffect(() => {
  //   if (articleId !== null) {
  //     dispatch(getDatBaseArticleThunk({ token, id: articleId }));
  //   }
  // }, [uText]);

  const handleEdit = async e => {
    setEditedText(uText);
    setEditedTitle(uTitle);
    setShowFileInput(true);
  };
  const handleClose = async e => {
    setEditedText('');
    setEditedTitle('');
    setShowFileInput(false);
  };
  const handleEditFile = async e => {
    const shiftData = {
      id: articleId,
      text: editedText,
      title: editedTitle,
      subtitle: 'string',
    };

    const response = await dispatch(
      putDatBaseArticleThunk({ token, neededData: shiftData })
    );

    if (response.meta.requestStatus === 'fulfilled') {
      Notify.success('Edited');
    } else {
      Notify.failure('Ooops! Something went wrong');
    }
    const formData = new FormData();
    formData.append('id', articleId);
    for (const file of files) {
      formData.append('image', file);
    }

    const responseImg = await dispatch(
      postDatBaseArticlePhotoThunk({ token, formData })
    );

    if (responseImg.meta.requestStatus === 'fulfilled') {
      dispatch(getDatBaseArticleThunk({ token, id: articleId }));
      setShowFileInput(false);
      setFiles('');
    } else {
      Notify.failure('Ooops! Something went wrong');
      console.log(responseImg);
    }
  };
  const removeFile = index => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };
  const handleDeleteArcticalPhoto = async e => {
    const response = await dispatch(
      deleteDatBaseArticlePhotoThunk({
        token,
        image_id: e,
        article_id: articleId,
      })
    );
    if (response.meta.requestStatus === 'fulfilled') {
      Notify.success('Deleted');
      dispatch(getDatBaseArticleThunk({ token, id: articleId }));
    } else {
      Notify.failure('Ooops! Something went wrong');
      console.log(response);
    }
  };

  if (!articleId) {
    return <Container></Container>;
  } else {
    return (
      <>
        {loading ? (
          <MutatingDots
            height="100"
            width="100"
            color="#AFFFB7"
            secondaryColor="#AFFFB7"
            radius="12.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        ) : (
          <Container>
            <ContainerMainInfo>
              <HeaderFileWrap>
                {showFileInput ? (
                  <>
                    <EditTitleArea
                      value={editedTitle}
                      onChange={e => setEditedTitle(e.target.value)}
                    />
                    <SettingWrap>
                      <SaveButton onClick={handleEditFile}>
                        Сохранить
                      </SaveButton>
                      <CancelButton onClick={handleClose}>
                        Отменить
                      </CancelButton>
                    </SettingWrap>
                  </>
                ) : (
                  <>
                    <TextNameFile switcher={switcher}>{uTitle}</TextNameFile>
                    {utype === 'admin' && <Pen onClick={handleEdit} />}
                  </>
                )}
              </HeaderFileWrap>

              <ContainerProfile>
                {!uPhoto && <UserImg src={userPhoto} alt="UserImg" />}
                {uPhoto && (
                  <UserImg
                    src={
                      `http${
                        process.env.REACT_APP_SECURE === 'true' ? 's' : ''
                      }://${process.env.REACT_APP_BACKEND_URL}` + uPhoto
                    }
                    alt="UserImg"
                  />
                )}

                <UserText>
                  <UserTitle>Created by {uCreatedBy}</UserTitle>
                  <UserTitle>{formattedDate}</UserTitle>
                </UserText>
              </ContainerProfile>
              {showFileInput ? (
                <EditTextArea
                  value={editedText}
                  onChange={e => setEditedText(e.target.value)}
                />
              ) : (
                <MainInfo switcher={switcher}>{uText}</MainInfo>
              )}
              <ImgContainer>
                {uImages?.map((image, id) => {
                  return (
                    <div key={id} style={{ position: 'relative' }}>
                      <DatBasePic
                        src={
                          `http${
                            process.env.REACT_APP_SECURE === 'true' ? 's' : ''
                          }://${process.env.REACT_APP_BACKEND_URL}` +
                          image.image
                        }
                        onClick={handleClick}
                        alt="DatBaseImg"
                      />
                      {showFileInput && (
                        <CloseButton
                          onClick={() => handleDeleteArcticalPhoto(image.id)}
                        >
                          <RedCross />
                        </CloseButton>
                      )}
                    </div>
                  );
                })}
                {/* <ImageGallery items={images} showPlayButton={false} showNav={false} thumbnailWidth={250} thumbnailHeight={200}/> */}

                {/* <UserLogoButton>
                  <ImgSpan>
                    See full <CameraIcon />
                  </ImgSpan>
                  <DatBasPic
                    src={DatDasPhoto}
                    // onClick={handleClick}
                    alt="DatBasImg"
                  />
                </UserLogoButton> */}
              </ImgContainer>
              {showFileInput && (
                <SettingPhotoWrap>
                  <PhotoLabel htmlFor="phInput">
                    <FileInputContainer>
                      <FileInputIcon>+</FileInputIcon>
                    </FileInputContainer>
                  </PhotoLabel>
                  <input
                    id="phInput"
                    type="file"
                    name="photos"
                    multiple
                    accept=".jpg, .jpeg, .png"
                    style={{ display: 'none' }}
                    onChange={e => setFiles(e.target.files)}
                  />
                  {files && (
                    <ImgContainer>
                      {[...files].map((file, index) => (
                        <div key={index} style={{ position: 'relative' }}>
                          <img
                            src={URL.createObjectURL(file)}
                            alt="Preview"
                            style={{
                              width: '230px',
                              height: '200px',
                              objectFit: 'cover',
                              borderRadius: '4px',
                            }}
                          />
                          <CloseButton onClick={() => removeFile(index)}>
                            <RedCross />
                          </CloseButton>
                        </div>
                      ))}
                    </ImgContainer>
                  )}
                </SettingPhotoWrap>
              )}
            </ContainerMainInfo>
          </Container>
        )}
      </>
    );
  }
};

export default DataBase;
