import styled from 'styled-components';
import { AiFillCheckCircle } from 'react-icons/ai';

export const TimeWindowModal = styled.div`
  position: fixed;
  top: 465px;
  left: 120px;
  background-color: #ffffff !important;
  border: 0.5px solid #d3d3d3;
  box-shadow: 0px 10px 13.1px rgba(0, 0, 0, 0.06);
  border-radius: 5px;
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  width: 195px;
  background-color: #ffffff !important;
`;

export const TimeSelect = styled.select`
  padding: 3px 25px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  -moz-appearance: none; /* Firefox */
  appearance: none;
  border-radius: 3px;
`;
export const LunchTimeSelect = styled.select`
  margin-left: 25px;
  padding: 3px 25px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  -moz-appearance: none; /* Firefox */
  appearance: none;
  border-radius: 3px;
`;

export const TimeOptions = styled.option`
  overflow: hidden;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #717171;
`;

export const TimeText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
`;
export const Confirm = styled(AiFillCheckCircle)`
  color: green;
  width: 18px;
  height: 15px;
`;
export const Disabled = styled(AiFillCheckCircle)`
  color: gray;
  width: 18px;
  height: 15px;
`;
export const ConfirmButton = styled.button`
  padding: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
`;

export const SpanWindow = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #000000;
`;
