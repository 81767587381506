import styled from 'styled-components';

export const Container = styled.div`
display:flex;
justify-content: center;
`;

export const Document = styled.textarea`
border:none;
height:100vh;
width: 1250px;
outline: none;
resize: none;
margin-top:10px;
padding-top:20px;
box-shadow: -10px 3px 5px -12px rgba(0,0,0,0.65), 10px 10px 5px -10px rgba(0,0,0,0.75), 10px -10px 7px -12px rgba(0,0,0,0.65);;

`;